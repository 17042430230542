import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config/config";
import axios from "axios";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";

export default function NodalAndAppellateContacts() {


  const [nodalData, setNodalData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);

  useEffect(() => {

    axios.post(`${API_BASE_URL}/api/nodal/getAllNodal`).then((response) => {

      setNodalData(response.data.data.nodal.nodal);
      setMetaData(response);
      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "nodal_banner") || null;
      const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");

      setSettingResponse(networkServicesSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }, [])

  return (
    <>
      {nodalData === null ? (
        <PageLoader />
      ) : (
        <>
         <MetaTag data={metaData} pageKey="nodal"/>
          <div className="page_heading">
            <div className="container">
              <h1>{settingResponse?.title}</h1> <p>{settingResponse?.sub_title}<span>{settingResponse?.italic}</span></p>
            </div>
          </div>
          <div className="cms spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
            <div className="container">
              <div className="address-wrap">

                {nodalData.map((item) => (
                  <div className="address-box" key={item.id}>
                    <div className="content">
                      <h3>{item.address_title}</h3>
                      <ul>
                        <li>
                          <span>Name -</span> {item.name}
                        </li>
                        <li>
                          <span>Email -</span>{" "}
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </li>
                        <li>
                          <span>Timing -</span> {item.timing}
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}