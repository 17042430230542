import React, { useEffect, useState } from "react";
import FrontVideo from "../components/homevideo";
import HeaderTitle from "../components/headerTitle";
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import Information from "../components/CommonSections/Information";
import WhatWeOffer from "../components/CommonSections/WhatWeOffer";
import Advantage from "../components/CommonSections/Advantage";
import CtaBanner from "../components/CommonSections/CtaBanner";
import PageLoader from "../components/home/pageload";
import ClientExperiences from "../components/home/client-experiences";
import MetaTag from "../components/CommonSections/MetaTag";
import Blogs from "../components/home/blogs";
import ServiceSchema from "../components/CommonSections/serviceSchema";

export default function NetworkServices() {

     const [networkServicesData, setNetworkServicesData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          axios.post(`${API_BASE_URL}/api/networkservices/getNetworkServices`).then((response) => {


               setNetworkServicesData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "network_service_advantage") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");
               networkServicesSettingsResponse.advantages = networkServicesSettingsResponse;
               setSettingResponse(networkServicesSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }, [])


     return (
          <>

               {networkServicesData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <ServiceSchema title={networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0].title} url='https://ishantechnologies.com/network-services' />
                    <MetaTag data={networkServicesData} pageKey="network_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={networkServicesData} pageKey="network_services" />
                              </div>
                         </div>
                         <div className="video top-heading hero-banner">
                              <FrontVideo homeData={networkServicesData} pageKey="network_services" />
                              
                              <div className="banner-content ">
                                   {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0]?.banner_title && (
                                   <h2 className='home-heading'>
                                             {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0].banner_title}
                                   </h2>
                                   )}

                                   {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0]?.banner_img_desc && (
                                   <p className="para">
                                             {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0].banner_img_desc}
                                   </p>
                                   )}

                                   {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0]?.button_img_name && (
                                   <a href={networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0].button_url}>
                                             <button className="button hero-btn">
                                             {networkServicesData?.data?.data?.network_services?.network_services_banner_data
                              [0]?.button_img_name}
                                             </button>
                                   </a>
                                   )}
                              </div>
                         </div>

                         <Information homeData={networkServicesData?.data?.data?.network_services?.network_services_information_data[0]?.description} />

                         <WhatWeOffer title={settingResponse?.what_we_offer} data={networkServicesData?.data?.data?.network_services?.network_services_offer_data} />

                         <Advantage title={settingResponse?.advantages} data={networkServicesData?.data?.data?.network_services?.network_services_advantages_data} />

                         {networkServicesData.data.data?.network_services?.blogs_info_data.length > 0 &&
                              <div className="blogs section-space">
                                   <Blogs homeData={networkServicesData} pageKey="network_services" />
                              </div>
                         }
                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={networkServicesData} pageKey="network_services" tag="network_service" />
                         </div>

                         <CtaBanner bannerData={networkServicesData?.data?.data?.network_services?.network_services_cta_banner_data} />
                    </>
               )}

          </>
     );
}