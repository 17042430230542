import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../images/logo.png";
import Navigation from "../navigation";
import HamburgerMenu from "./hamburger";

export default function Header({ homeData }) {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container">
      <div className="h-lft">
        <Link to="/" className="logo">
          <img src={homeData?.data?.data?.header?.header_logo_data[0]?.primary_logo || LogoImage} alt={homeData?.data?.data?.header?.header_logo_data[0]?.altText || LogoImage} loading="lazy" height={39} width={305}/>
        </Link>
      </div>
      <div className="h-rgt">
        <div className="h-navigation">
          <Navigation isOpen={isOpen} toggleMenu={toggleMenu} homeData={homeData} />
        </div>
        <HamburgerMenu isOpen={isOpen} toggleMenu={toggleMenu} />
      </div>
    </div>
  );
}