import * as React from "react"
const IconYoutube = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="0 0 152 152"
    {...props}
  >
    <g data-name="Layer 2">
      <g data-name="02.YouTube">
        <circle cx={76} cy={76} r={76} fill="red" />
        <path
          fill="#fff"
          d="M100.87 47.41H51.13A15.13 15.13 0 0 0 36 62.55v26.9a15.13 15.13 0 0 0 15.13 15.14h49.74A15.13 15.13 0 0 0 116 89.45v-26.9a15.13 15.13 0 0 0-15.13-15.14zM65.46 88.26V63.74L86.54 76z"
        />
      </g>
    </g>
  </svg>
)
export default IconYoutube