import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_BASE_URL, CRM_URL, SITE_KEY, FORM_AUTH_TOKEN} from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

import ReactRecaptcha3 from 'react-google-recaptcha3';
import { xssSafeString, sanitizeInput, getApiAccessToken } from '../../utils/helper';

export default function PlansPopup({ planData }) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [getAuthToken, setAuthToken] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        var result = await getApiAccessToken();
        setAuthToken(result.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };
    fetchToken();
    const tokenInterval = setInterval(fetchToken, 60000);
    return () => clearInterval(tokenInterval);
  }, [])

  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {
 
      }
    );
  }, [])


  const handleSubmit = (newNav) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {
        let data = {
          Details: newNav,
          token: token
        }

        setLoading(true);
        axios.post(`${CRM_URL}?cmd=inquiry`, data,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken}`
          }
        }).then((response) => {
          setLoading(false);
          setFormSubmitted(true);
          axios.post(`${API_BASE_URL}/api/website/createInquery`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      })
  };

  const [navigation, setNav] = useState(null);
  const validationInquiry = useFormik({
    enableReinitialize: true,

    initialValues: {

      name: (navigation && navigation.name) || "",
      phone_number: (navigation && navigation.phone_number) || "",
      city: (navigation && navigation.city) || "",
      address: (navigation && navigation.address) || "",
      plan_detail: (navigation && navigation.plan_detail) || planData,


    },
    validationSchema: Yup.object({

      name: xssSafeString("Please Enter Name").max(50, 'Must be 50 characters or less'),
      city: xssSafeString("Please Enter City").max(50, 'Must be 50 characters or less'),                       
      phone_number: Yup.string()
        .required('Please Enter Phone Number')
        .matches(
          /^\d{10}$/,
          'Phone number must be exactly 10 digits'
        ).transform((value) => sanitizeInput(value)), 
      address: xssSafeString("Please Enter Address").max(100, 'Must be 100 characters or less'),
      plan_detail: xssSafeString("Please select Plan Detail").max(50, 'Must be 50 characters or less'),

    }),

    onSubmit: (values) => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmSource = urlParams.get('utm_source')|| '';
      const utmMedium = urlParams.get('utm_medium')|| '';

      const newNav = {
        name: sanitizeInput(values.name),
        city: sanitizeInput(values.city),
        phone_number: sanitizeInput(values.phone_number),
        address: sanitizeInput(values.address),
        plan_detail: sanitizeInput(values.plan_detail),
        utm_source:sanitizeInput(utmSource),
        utm_medium:sanitizeInput(utmMedium)
      };

      handleSubmit(newNav)
      validationInquiry.resetForm();

    },
  });
  return (
    <>
      <div className="form-wrapper popupform">

        <Form
          id="createNav"
          className={`${formSubmitted ? "submitted-success" : ""}${loading ? "loader-active" : ""}`}
          onSubmit={(e) => {
            e.preventDefault();
            validationInquiry.handleSubmit();
            return false;
          }}
        >
          <h2>Enquire Now </h2>
          <div className='item'>
            <label htmlFor="name">Name:</label>
            <Input type="text" id="name" name="name"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.name || ""}
              invalid={
                validationInquiry.touched.name && validationInquiry.errors.name
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.name && validationInquiry.errors.name ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.name}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="phone_number">Phone Number:</label>
            <Input type="text" id="phone_number" name="phone_number"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.phone_number || ""}
              invalid={
                validationInquiry.touched.phone_number && validationInquiry.errors.phone_number
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.phone_number && validationInquiry.errors.phone_number ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.phone_number}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="city">City:</label>
            <Input type="text" id="city" name="city"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.city || ""}
              invalid={
                validationInquiry.touched.city && validationInquiry.errors.city
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.city && validationInquiry.errors.city ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.city}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="address">Address:</label>
            <textarea
              name="address"
              value={validationInquiry.values.address}
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              className={`form-control ${validationInquiry.touched.address && validationInquiry.errors.address ? 'is-invalid' : ''
                }`}
            ></textarea>
            {validationInquiry.touched.address && validationInquiry.errors.address ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.address}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item'>
            <label htmlFor="plan_detail">Plan Detail:</label>
            <Input type="text" id="plan_detail" name="plan_detail"
              onChange={validationInquiry.handleChange}
              onBlur={validationInquiry.handleBlur}
              value={validationInquiry.values.plan_detail || planData}
              readOnly
              invalid={
                validationInquiry.touched.plan_detail && validationInquiry.errors.plan_detail
                  ? true
                  : false
              }
            />
            {validationInquiry.touched.plan_detail && validationInquiry.errors.plan_detail ? (
              <FormFeedback type="invalid">
                {validationInquiry.errors.plan_detail}
              </FormFeedback>
            ) : null}
          </div>
          <div className='item submit-item'>
            <button type="submit" className="button btn-pacific-blue">Submit</button>
          </div>
          {errorMsg &&
            <div className="form-error-msg form-text-center">
              <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
            </div>
          }
          {formSubmitted && (
            <div className='thanks-msg'>
              <div className="main-container">
                <div className="check-container">
                  <div className="check-background">
                    {/* <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                      <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> */}

                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 25L27.3077 44L58.5 7" 
                            stroke="white" 
                            stroke-width="13" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                            class="animate-path" />
                    </svg>

                  </div>
                  <div className="check-shadow"></div>
                </div>
                <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
              </div>
            </div>
          )}
          
          {loading && (
            <div className='loader-sec'>
              <div className="main-container">
                <div className="check-container">
                  <span class="loader"></span>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}					