import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import PageLoader from "../../components/home/pageload";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function LatestUpdatesDetails() {
     const { slug } = useParams();
     const [data, setData] = useState(null);
     const [schemadata, setschemadata] = useState(null);
     const currentUrl = window.location.href;
     const cleanedUrl = currentUrl.replace(/\/$/, ''); 
     useEffect(() => {
          const requestData = {
               "slug": slug
          };

          axios.post(`${API_BASE_URL}/api/latestupdates/getBlogData`, requestData).then((response) => {

               if (response.data.error === false) {
                    setData(response?.data?.data);
                    let blogResponse = response?.data?.data[0];
                    const btnUrl = blogResponse.button_url;
                    const titleData = blogResponse.title;
                    const description = blogResponse.description;
                    const image = blogResponse.image;
                    
                    const dynamicData = {
                    mainEntityOfPage: {
                         '@type': 'WebPage',
                         '@id': btnUrl
                    },
                    headline: titleData,
                    description: description,
                    image: image,
                    author: {
                         '@type': 'Organization',
                         name: '',
                    },
                    publisher: {
                         '@type': 'Organization',
                         name: 'Ishan Technologies',
                         logo: {
                         '@type': 'ImageObject',
                         url: 'https://ishantechnologies.com/api/static/15755ishan_uniquely_c_logo__305_pix_png.png',
                         },
                    },
                    datePublished: 'Dynamic - Date Published',
                    };
                   
                    setschemadata(dynamicData);

               }
          }).catch((error) => {
               <PageLoader />
          })
     }, [])
     return (
       <>
         {schemadata !== null && (
           <script type="application/ld+json">
             {JSON.stringify({
               "@context": "https://schema.org",
               "@type": "BlogPosting",
               schemadata,
             })}
           </script>
         )}
         {data !== null && data[0]?.meta_title != "" ? (
           <div>
             <Helmet>
               <link rel="canonical" href={cleanedUrl} />
               <title>
                 {data[0]?.meta_title !== ""
                   ? data[0]?.meta_title
                   : "Ishan Technologies - Uniquely Yours"}
               </title>
               {data[0]?.meta_description !== "" && (
                 <meta name="description" content={data[0]?.meta_description} />
               )}
               {data[0]?.meta_keywords !== "" && (
                 <meta name="keywords" content={data[0]?.meta_keywords} />
               )}
             </Helmet>
           </div>
         ) : (
           <div>
             <Helmet>
               <title>Ishan Technologies - Uniquely Yours</title>
             </Helmet>
           </div>
         )}
         <div className="events-details spin-wrap">
           <div className="spin-round-wrap">
             <div className="spin-round color-navy-blue">
               <span className="ballDot1">1</span>
               <span className="ballDot2">2</span>
               <span className="ballDot3">3</span>
               <span className="ballDot4">4</span>
             </div>
           </div>
           {data !== null && (
             <>
               <div className="container">
                 {data.length > 0 ? (
                   <>
                     <div className="event-banner-img">
                       {
                         <img
                           src={data[0].image}
                           alt={data[0].altText}
                           loading="lazy"
                           height={550}
                           width={1155}
                         />
                       }
                     </div>
                     <p className="breadcrumb">
                       <Link to="/latest-updates">LATEST UPDATE</Link> /{" "}
                       {data[0].title.toUpperCase()}
                     </p>

                     <h1>{data[0].title}</h1>
                     <div
                       className="event-information"
                       dangerouslySetInnerHTML={{
                         __html: data[0]?.description,
                       }}
                     ></div>
                   </>
                 ) : (
                    <div class="error-wrap section-space spin-wrap"><div class="container"><span class="text-4">4</span><div class="error-page-load snippet" data-title="dot-gathering"><div class="stage filter-contrast"><div class="dot-gathering"></div></div></div><span class="text-4">4</span><h1 class="error-heading">Page not found</h1></div></div>
                 )}
               </div>
             </>
           )}
            <div className="spin-round-wrap">
             <div className="spin-round">
               <span className="ballDot1">1</span>
               <span className="ballDot2">2</span>
               <span className="ballDot3">3</span>
               <span className="ballDot4">4</span>
             </div>
            </div>
         </div>
       </>
     );
}