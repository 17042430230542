import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL } from "../../config/config";


function LifeAtIshan({ careerData = [] }) {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 991, // tablet breakpoint
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 575, // mobile breakpoint
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {careerData?.map((lifeIshan) => (
                <div className="ishantec-inner" key={lifeIshan.id}>
                    <div className="inner-img">
                        {<img src={lifeIshan.image} alt={lifeIshan.altText} height={290} width={357} loading="lazy"/>}
                    </div>
                    <div className="inner-text">
                        {lifeIshan.icon !== `${API_BASE_URL}/api/static/` ? (
                            <img src={lifeIshan.icon} alt={lifeIshan.altText} loading="lazy"/>
                        ) : (
                            <h3>{lifeIshan.title}</h3>
                        )}
                    </div>
                </div>
            ))}
        </Slider>
    );
}

export default LifeAtIshan;