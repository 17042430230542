import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import PageLoader from "../../components/home/pageload";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function EventsDetails() {
     const { slug } = useParams();
     const [data, setData] = useState(null);
     const currentUrl = window.location.href;
     const cleanedUrl = currentUrl.replace(/\/$/, ''); 
     useEffect(() => {
          const requestData = {
               "slug": slug
          };

          axios.post(`${API_BASE_URL}/api/events/getEventsData`, requestData).then((response) => {

               if (response.data.error === false) {
                    setData(response?.data?.data);

               }
          }).catch((error) => {
               <PageLoader />
          })
     }, [])
     return (
          <>
                {data !== null && data[0]?.meta_title != "" ? (
                <div>
                    <Helmet>
                        <title>
                            {data[0]?.meta_title !== "" ? (
                                data[0]?.meta_title
                            ) : (
                                "Ishan Technologies - Uniquely Yours"
                            )}
                        </title>
                        {data[0]?.meta_description !== "" &&
                            <meta name="description" content={data[0]?.meta_description} />
                        }
                        {data[0]?.meta_keywords !== "" &&
                            <meta name="keywords" content={data[0]?.meta_keywords} />
                        }
                    </Helmet>
                </div>
            ) : (
                <div>
                    <Helmet>
                    <link rel="canonical" href={cleanedUrl} />
                        <title>Ishan Technologies - Uniquely Yours</title>
                    </Helmet>
                </div>
            )}
               <div className="events-details spin-wrap">
                    <div className="spin-round-wrap">
                         <div className="spin-round color-navy-blue">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
                    {data !== null &&
                    <>
                         <div className="container">
                 {data.length > 0 ? (
                   <>
                              <div className="event-banner-img">
                                   {<img src={data[0].image} height={550} width={1190} alt={data[0].altText} loading="lazy"/>}
                              </div>
                              <p className="breadcrumb"><Link to="/events">EVENTS</Link> / {data[0].title.toUpperCase()}</p>

                              <div className="event-information" dangerouslySetInnerHTML={{ __html: data[0]?.description }}>
                              </div>
                        
                          </>
                          ) : (
                             <div class="error-wrap section-space spin-wrap"><div class="container"><span class="text-4">4</span><div class="error-page-load snippet" data-title="dot-gathering"><div class="stage filter-contrast"><div class="dot-gathering"></div></div></div><span class="text-4">4</span><h1 class="error-heading">Page not found</h1></div></div>
                          )}
                        </div>
                        </>
                    }
                    <div className="spin-round-wrap">
                         <div className="spin-round">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
               </div>
          </>
     );
}