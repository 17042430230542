import * as React from "react"
const IconFacebook = (props) => (
    <svg xmlns="https://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <path
        fill="#1777EF"
        fillRule="evenodd"
        d="M38.983 19.748c0-10.62-8.608-19.23-19.227-19.23S.528 9.128.528 19.748c0 9.597 7.031 17.552 16.223 18.995V25.306h-4.882v-5.558h4.882v-4.237c0-4.819 2.871-7.481 7.263-7.481 2.104 0 4.304.375 4.304.375v4.733h-2.425c-2.388 0-3.133 1.482-3.133 3.002v3.608h5.333l-.853 5.558h-4.48v13.437c9.192-1.443 16.223-9.398 16.223-18.995Z"
        />
        <path
        fill="#FFF"
        fillRule="evenodd"
        d="m27.24 25.306.853-5.558H22.76V16.14c0-1.52.745-3.002 3.133-3.002h2.425V8.405s-2.2-.375-4.304-.375c-4.392 0-7.263 2.662-7.263 7.481v4.237h-4.882v5.558h4.882v13.437a19.455 19.455 0 0 0 6.009 0V25.306h4.48Z"
        />
    </svg>
)
export default IconFacebook