import React, { useState, useEffect } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  useEffect(() => {
    setActiveIndex(0);
  }, []);

  return (
    <>
      {items !== undefined && items.length > 0 && (
        <div className="accordion">
          {items.map((item, index) => (
            <div className="accordion-item" key={index}>
              <button
                className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                {item.question}
                <span className="expand-bar"></span>
              </button>
              <div
                className={`accordion-content ${activeIndex === index ? 'active' : ''}`}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Accordion;
