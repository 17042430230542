import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import ColocationPopup from "../../components/colocation-popup";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

const Advantage = ({ title, data = [], action = '', pageName ='' }) => {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const [requestGet, setRequest] = useState(action);
     const [getPageName, setPageName] = useState(pageName);

     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };  

     return (
          <div className="advantage section-space spin-wrap">
               <div className="spin-round-wrap">
                    <div className="spin-round color-navy-blue">
                         <span className='ballDot1'>1</span>
                         <span className='ballDot2'>2</span>
                         <span className='ballDot3'>3</span>
                         <span className='ballDot4'>4</span>
                    </div>
               </div>
               <div className="container">
                    <h2>The Ishan Advantage</h2>
                    <div className="advantage-inner">

                         {data.map((advantage, index) => (
                              <div className="advantage-row" key={index}>
                                   <div className="advantage-rowinner">
                                        <img src={advantage.icon} alt={advantage.altText} height={60} width={60} loading="lazy"/>
                                        <h4>{advantage.title}</h4>
                                        <p>{advantage.description}</p>
                                   </div>
                              </div>
                         ))}
                    </div>
                    {title && (
                    requestGet == 'colocation' ? (
                         <button className="button btn-advantage" onClick={() => openModal()}>
                         Get In Touch
                    </button>
                    ) : (
                    <Link to={title?.button_url}>
                    <button className="button btn-advantage" onClick={handleClick}>
                    {title?.button_title}
                    </button>
                    </Link>
                    )
                    )}
               </div>

               <Modal isOpen={modal} toggle={toggle}>
                    <ModalBody>
                    <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                     <ColocationPopup colocationData={colocationData} pageName={getPageName} />
                    </ModalBody>
               </Modal>
               <div className="spin-round-wrap">
                    <div className="spin-round">
                         <span className='ballDot1'>1</span>
                         <span className='ballDot2'>2</span>
                         <span className='ballDot3'>3</span>
                         <span className='ballDot4'>4</span>
                    </div>
               </div>
          </div>
     )
}

export default Advantage