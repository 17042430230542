import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from '../components/home/pageload';
import FrontVideo from "../components/homevideo";
import MetaTag from "../components/CommonSections/MetaTag";
import { Link } from "react-router-dom";


export default function LatestUpdates() {
     const [activeTab, setActiveTab] = useState(1);
     const handleTabClick = (tabNumber) => {
          setActiveTab(tabNumber);
     };
     const handleClick = () => {
          window.scrollTo(0, 0);
     }
     useEffect(() => {
          getData();
     }, [])

     const [latestUpdate, setLatestUpdate] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const getData = () => {

          axios.post(`${API_BASE_URL}/api/latestupdates/getAllLatestUpdates`).then((response) => {

               setLatestUpdate(response);
               setActiveTab(response?.data?.data?.latest_updates?.latest_updates_menu_data[0].id)

               const generalSettingsData = response?.data?.data?.latest_updates?.latest_updates_banner_data;

               const BlogSettingsData = generalSettingsData.find((item) => item.tag === "latest_updtate") || null;
               const BlogSettingsResponse = JSON.parse(BlogSettingsData?.response || "{}");
               
               setSettingResponse(BlogSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }

     // Function to truncate the description
     function truncateDescription(description) {
          if (!description) {
               return '';
          }

          // Split the description into words
          const words = description.split(' ');

          // Check if there are more than 11 words
          if (words.length > 10) {
               const truncatedText = words.slice(0, 11).join(' ');
               const truncatedWithEllipsis = truncatedText + '...';
               return truncatedWithEllipsis;
          } else {
               return description;
          }
     }
     return (
          <>
          {latestUpdate &&
          <>
               <MetaTag data={latestUpdate} pageKey="latest_updates"/>
               {latestUpdate?.data?.data?.latest_updates?.latest_updates_banner_data.length > 0 &&
                    <>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   {settingResponse?.title != "" && 
                                   <h1>{settingResponse?.title}</h1>
                                   }
                                   {settingResponse?.sub_title != "" && 
                                   <p>{settingResponse?.sub_title}
                                        <span>{settingResponse?.italic}</span>
                                   </p>
                                   }
                              </div>
                         </div>
                    </>
               }
               {latestUpdate?.data?.data?.latest_updates?.latest_updates_information_data.length > 0 &&
                    <div className="sec-staying-ahead section-space">

                         <div className="container" dangerouslySetInnerHTML={{ __html: latestUpdate?.data?.data?.latest_updates?.latest_updates_information_data[0]?.description }}>
                         </div>
                    </div>
               }


               <div className="weoffer weoffer-white section-space spin-wrap">
                    <div className="spin-round-wrap">
                         <div className="spin-round color-navy-blue">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
                    {latestUpdate?.data?.data?.latest_updates?.latest_updates_menu_data.length > 0 &&
                         <div className="container">
                              <div className="weoffer-inner">
                                   <div className="weoffer-inner-tab">
                                        <ul className="tabs shortest-tabs">

                                             {latestUpdate?.data?.data?.latest_updates?.latest_updates_menu_data.map((offer, index) => (

                                                  <li key={index} className={activeTab === offer.id ? "actived" : ""}>
                                                       <button
                                                            className={activeTab === offer.id ? "tab-btn tab-btn-bgdark active" : "tab-btn tab-btn-bgdark"}
                                                            onClick={() => handleTabClick(offer.id)} tabIndex={0}
                                                       >
                                                            {offer.title}
                                                       </button>
                                                  </li>

                                             ))}
                                        </ul>
                                   </div>
                                   <div className="weoffer-details">
                                        <div className="tab-content tab-content-bgdark">
                                             <div className="blogname">


                                                  {latestUpdate?.data?.data?.latest_updates?.latest_updates_data.map((offer, index) => (
                                                       <React.Fragment key={index}>
                                                            {activeTab === offer.parent_id && (
                                                                 <div className="blog-inner">
                                                                      <Link to={`/latest-updates${offer?.button_url}`}>
                                                                           <img src={offer?.image} alt={offer?.altText} height={190} width={368} loading="lazy"/>
                                                                      </Link>
                                                                      <div className="blog-text list-summery">
                                                                           <Link className="action-link" to={`/latest-updates${offer?.button_url}`}>
                                                                                <h5>{offer?.title}</h5>
                                                                           </Link>
                                                                           {/* Truncate the description here */}
                                                                           <div dangerouslySetInnerHTML={{ __html: truncateDescription(offer?.description) }}></div>
                                                                           <Link to={`/latest-updates${offer?.button_url}`}>
                                                                                <button className="button btn-grayish" onClick={handleClick}>
                                                                                     Know more
                                                                                </button>
                                                                           </Link>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                       </React.Fragment>
                                                  ))}

                                                  {latestUpdate?.data?.data?.latest_updates?.latest_updates_data.every(offer => activeTab !== offer.parent_id) && (
                                                       <p>Data not found</p>
                                                  )}



                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    }
                    <div className="spin-round-wrap">
                         <div className="spin-round">
                              <span className='ballDot1'>1</span>
                              <span className='ballDot2'>2</span>
                              <span className='ballDot3'>3</span>
                              <span className='ballDot4'>4</span>
                         </div>
                    </div>
               </div>
               </>
               }
          </>
     );
}