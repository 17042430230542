import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import CtaBanner from "../../components/CommonSections/CtaBanner";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgCoLocation from "../../assest/images/IshanColocation.png";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import { Link } from "react-router-dom";
import ColocationPopup from "../../components/colocation-popup";
import ColocationBannerForm from "../../components/colocation-banner-from";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function Colocation() {
     useEffect(() => {
          getData();
     }, [])

     const [colocationServicesData, setColocationServicesData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);

     const getData = () => {
          axios.post(`${API_BASE_URL}/api/colocation/getColocation`).then((response) => {

               setColocationServicesData(response);

          }).catch((error) => {
               <PageLoader />
          })
     }

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   

     return (

          <>
               {colocationServicesData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data
                              [0].title} url='https://ishantechnologies.com/data-center-services/colocation' parentTitle='Data Centre and Cloud Services' parentUrl='https://ishantechnologies.com/data-center-services' />
                     <MetaTag data={colocationServicesData} pageKey="colocation_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={colocationServicesData} pageKey="colocation_services" />
                              </div>
                         </div>
                         <div className="video hero-banner internet-banner">
                              <FrontVideo homeData={colocationServicesData} pageKey="colocation_services" />
                              
                         <div className="banner-content ">
                              <div className="content-blk">
                                   {colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data[0]?.banner_title && (
                                   <h2 className='home-heading'>
                                        {colocationServicesData.data.data.colocation_services.colocation_services_banner_data[0].banner_title}
                                   </h2>
                                   )}

                                   {colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data[0]?.banner_img_desc && (
                                   <p className="para">
                                        {colocationServicesData.data.data.colocation_services.colocation_services_banner_data[0].banner_img_desc}
                                   </p>
                                   )}

                                   {colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data[0]?.button_img_name && (
                                   <a href={colocationServicesData.data.data.colocation_services.colocation_services_banner_data[0].button_url}>
                                        <button className="button hero-btn">
                                        {colocationServicesData?.data?.data?.colocation_services?.colocation_services_banner_data[0]?.button_img_name}
                                        </button>
                                   </a>
                                   )}
                              </div>
                              <div className="form-blk">
                                   <ColocationBannerForm homeData={colocationServicesData} pageName="ColocationMainPage" />
                              </div> 
                         </div>

                              
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgCoLocation} alt="Co-location Services" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: colocationServicesData?.data?.data?.colocation_services?.colocation_services_information_data[0]?.description }}>
                                        </div>
                                   </div>
                                   <div className="colocation_popup"> 
          
                                        <button className="button getintouch" onClick={() => openModal()}>Get In Touch</button>
                                   </div>
                              </div>

                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                              
                         </div>

                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} pageName="ColocationLandingPage" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); setInTuchPlanModal(false); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <ColocationBannerForm homeData={colocationData} pageName="ColocationMainPage" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn"  onClick={(e) => { e.preventDefault(); setInTuchPlanModal(true); }}>GET IN TOUCH</a>

                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={colocationServicesData?.data?.data?.colocation_services?.colocation_services_offer_data} />
                         </div>


                         <div className="ishan-map sec-data-footprint">
                              <div className="container">
                                   <div className="footprint-txt">
                                        Our Data Centre Footprint
                                   </div>
                              </div>
                         </div> 

                         <div className="ishan-map sec-co-locations">
                              <div className="container row">
                                   <div className="locations">
                                        <img src="https://ishantechnologies.com/api/static/colocation/ahmedabad.png" alt="Description 3"  loading="lazy" />
                                        <div className="ds-desc">
                                             <p className="label">Gandhinagar</p>
                                             <a href="/data-center-services/colocation/gandhinagar" className="cta-button">Know More</a>
                                        </div>
                                        
                                   </div>
                                   <div className="locations">
                                        <img src="https://ishantechnologies.com/api/static/colocation/chennai.png" alt="Description 2" loading="lazy" />
                                        <div className="ds-desc">
                                             <p className="label">Chennai</p>
                                             <a href="/data-center-services/colocation/chennai" className="cta-button">Know More</a>
                                        </div>
                                        
                                   </div>
                                   <div className="locations">
                                        <img src="https://ishantechnologies.com/api/static/colocation/mumbai.png" alt="Description 1"  loading="lazy" />
                                        <div className="ds-desc">
                                        <p className="label">Mumbai</p>
                                        <a href="/data-center-services/colocation/mumbai" className="cta-button">Know More</a>
                                        </div>
                                        
                                   </div>
                              </div>
                         </div>


                         {colocationServicesData?.data?.data?.colocation_services?.colocation_map_data.length > 0 &&
                              <div className="ishan-map sec-ishan-map">
                                   <div className="container">
                                        <div className="indian-places">
                                             {<img src={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.sub_image} alt={colocationServicesData?.data?.data?.colocation_services?.colocation_map_data[0]?.iconAltText} height={173} width={1128} loading="lazy" />}
                                        </div>
                                   </div>
                              </div> 
                         }

                         <CtaBanner bannerData={colocationServicesData?.data?.data?.colocation_services?.colocation_services_cta_banner_data} />
                    </>
               )}
          </>
     );
}