import React, { useState, useEffect } from 'react';
import MetaTag from "../components/CommonSections/MetaTag";
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from '../components/home/pageload';
import { Link } from "react-router-dom";

export default function OurCustomers() {
  useEffect(() => {
    getData();
  }, [])

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  const [ourCustomersData, setOurCustomersData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);

  const getData = () => {
    axios.post(`${API_BASE_URL}/api/ourcustomer/getAllOurCustomers`).then((response) => {

      setOurCustomersData(response);

      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "our_customer_banner") || null;
      const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");
      networkServicesSettingsResponse.advantages = networkServicesSettingsResponse;
      setSettingResponse(networkServicesSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }

  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      {ourCustomersData === null ? (
        <PageLoader />
      ) : (
        <>
        <MetaTag data={ourCustomersData} pageKey="our_customers"/>
          <div className="page_heading responsive_heading">
            <div className="container">
              <h1>{settingResponse?.title}</h1><p>{settingResponse?.sub_title}<span>{settingResponse?.italic}</span></p>
            </div>
          </div>

          <div className="our-customer md-section-space spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>

            <div className="container">
              <h2 className="title-heading">Powering diverse industries with expertly tailored solutions</h2>
              <p className="title-para">The vast clientele we serve speaks volumes about our versatility and overall dynamism. Our flexible and agile approach helps us scale and enables us to serve a wide range of industries</p>

              <div className="accordion">

                {ourCustomersData?.data?.data?.our_customers?.our_customer_tag_data.map((tag, index) => (
                  <div className="accordion-item" key={tag.id}>
                    <button className={`accordion-title ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                      <img src={tag.icon} alt={tag.altText} loading="lazy" height={40} width={40}/> {tag.tag} <span className="expand-bar"></span>
                    </button>
                    <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
                      <ul className="esteemed-icon">
                        {ourCustomersData?.data?.data?.our_customers?.our_customer_data
                          .filter((customer) => customer.our_cutomers_tag_id === tag.id)
                          .map((customer) => (
                            <li key={customer.id}>
                              <img src={customer.image} alt={customer.altText} height={60} width={181} loading="lazy"/>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}

              </div>
            </div>

            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>

          <div className="supercharge supercharge-left">
            <div className="container">
              <h2 className="supercharge-heading">{ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.title}</h2>
              <div className="supercharge-btns">
                {ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_title_1 &&
                  <Link to={ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_url_1}>
                    <button className="getintouch" onClick={handleClick}>{ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                  </Link>
                }
                {ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_title_2 &&
                  <Link to={ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_url_2}>
                    <button className="getintouch" onClick={handleClick}>{ourCustomersData?.data?.data?.our_customers?.our_customer_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                  </Link>
                }
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}