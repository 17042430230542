import React from 'react';

const HeaderTitle = ({ headerTitledata, pageKey }) => {

  const title = headerTitledata?.data?.data?.[pageKey]?.[`${pageKey}_banner_data`]?.[0]?.title;
  const sub_title = headerTitledata?.data?.data?.[pageKey]?.[`${pageKey}_banner_data`]?.[0]?.sub_title;
  const italic = headerTitledata?.data?.data?.[pageKey]?.[`${pageKey}_banner_data`]?.[0]?.italic;

  return (
    <>
      {title &&
        <h1>{title}</h1>
      }
      {sub_title &&
        <p>
          {sub_title}
          {italic && (
            <span>{italic}</span>
          )}
        </p>
      }
    </>
  );
};

export default HeaderTitle;