import React, { useState, useEffect } from 'react';
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

export default function Layout({ children, homeData }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`header ${isScrolled ? 'stuck' : ''}`}>
        <Header homeData={homeData} />
      </div>
      <div className="inner-wrap">
        {children}
      </div>
      <div className="footer">
        <Footer homeData={homeData} />
      </div>
    </>
  )
}