import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import ColocationPopup from "../../components/colocation-popup";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

const CtaBanner = ({ bannerData = [] }) => {

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };

  return (
    <div className="supercharge">
      <div className="container">
        <h2 className="supercharge-heading">{bannerData[0]?.title} </h2>
        <div className="supercharge-btns">
          {bannerData[0]?.button_title_1 !== "" &&
            <Link to={bannerData[0]?.button_url_1}>
              <button className="getintouch" onClick={handleClick}> {bannerData[0]?.button_title_1} </button>
            </Link>
          }

          {bannerData[0]?.button_title_2 !== "" && (
              <Link to={bannerData[0]?.button_url_2} target="_blank">
                <button className="getintouch" onClick={handleClick}>{bannerData[0]?.button_title_2}</button> 
              </Link>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalBody>
            <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
            <ColocationPopup colocationData={colocationData} />
            </ModalBody>
        </Modal>
      </div>
    </div>
    
  )
}

export default CtaBanner