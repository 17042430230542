import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";

export default function PrivacyPolicy() {

  useEffect(() => {
    getData();
  }, [])

  const [termAndCondition, settermAndCondition] = useState(null);
  const [metaData, setMetadata] = useState(null);
  const [bannerTitle, setBannerTitle] = useState(null);

  const getData = () => {
    const requestData = {
      "service_tag": "term_and_condition"
    };
    axios.post(`${API_BASE_URL}/api/policy/getTermAndCondition`, requestData).then((response) => {

      if (response.data.error === false) {
        settermAndCondition(response.data.data);
        setMetadata(response);
        const bannerTitleResponse = JSON.parse(response.data.data?.term_and_condition?.term_and_condition_banner_data[0]?.response || "{}");

        setBannerTitle(bannerTitleResponse);
      }
    }).catch((error) => {
      <PageLoader />
    })
  }
  return (
    <>
    {termAndCondition &&
    <>
    <MetaTag data={metaData} pageKey="term_and_condition"/>
      <div className="page_heading responsive_heading">
        <div className="container">
          <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
        </div>
      </div>

      <div className="our-customer md-section-space spin-wrap">
        <div className="spin-round-wrap">
          <div className="spin-round color-navy-blue">
            <span className='ballDot1'>1</span>
            <span className='ballDot2'>2</span>
            <span className='ballDot3'>3</span>
            <span className='ballDot4'>4</span>
          </div>
        </div>

        <div className="container" dangerouslySetInnerHTML={{ __html: termAndCondition?.term_and_condition?.term_and_condition_information_data[0]?.description }}>
        </div>
        <div className="spin-round-wrap">
          <div className="spin-round">
            <span className='ballDot1'>1</span>
            <span className='ballDot2'>2</span>
            <span className='ballDot3'>3</span>
            <span className='ballDot4'>4</span>
          </div>
        </div>
      </div>
      </>
      }
    </>
  );
}