import React, { useEffect } from 'react';

const HamburgerMenu = ({ isOpen, toggleMenu }) => {

  useEffect(() => {
    const navElement = document.querySelector('.h-navigation');

    if (isOpen) {
      navElement.classList.add('menu-open');
    } else {
      navElement.classList.remove('menu-open');
    }
  }, [isOpen]);

  return (
    <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
      <div className="hamburger-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
