import React from 'react'

const ServiceSchema = ({ title, url }) => {

    


     return (
          
         <></>
          
     )
}

export default ServiceSchema