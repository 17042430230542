import React from "react";
import MetaTag from "../components/CommonSections/MetaTag";

export default function CommonPage() {
  return (
    <>
    <MetaTag data={""} pageKey="Page_Not_Found"/>
      <div className="error-wrap section-space spin-wrap">
        <div className="spin-round-wrap">
          <div className="spin-round color-navy-blue">
            <span className='ballDot1'>1</span>
            <span className='ballDot2'>2</span>
            <span className='ballDot3'>3</span>
            <span className='ballDot4'>4</span>
          </div>
        </div>

        <div className="container">
          <span className="text-4">4</span>
          <div className="error-page-load snippet" data-title="dot-gathering">
            <div className="stage filter-contrast">
              <div className="dot-gathering"></div>
            </div>
          </div>
          <span className="text-4">4</span>
          <h1 className="error-heading">Page not found</h1>
        </div>

        <div className="spin-round-wrap">
          <div className="spin-round">
            <span className='ballDot1'>1</span>
            <span className='ballDot2'>2</span>
            <span className='ballDot3'>3</span>
            <span className='ballDot4'>4</span>
          </div>
        </div>
      </div>
    </>
  );
}