import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import Potential from "../../assest/images/potential.jpg";
import BusinessValue from "../../assest/images/business-value.jpg";
import PlansPopup from "../contacts/plans-popup";
import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import ServerStorageSec from "../../components/interner-service";

import DownloadIcon from "../../assest/images/download-icon.png";
import ECGMultiCloud from "../../assest/dell-server-storage/ecg_multi_cloud.pdf";
import ESGInfoGraphic from "../../assest/dell-server-storage/esg_Infographic.pdf";
import DellApexBrochure from "../../assest/dell-server-storage/apex_brochure_print.pdf";

import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function DellServerStorage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [ServerStorage, setServerStorage] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const [getViewLargeImage, setViewLargeImage] = useState(null);

     const [getLargeImageStatus, setLargeImageStatus] = useState(false);
     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "server_storage"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setServerStorage(response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const ServerStoragesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const ServerStoragesSettingsResponse = JSON.parse(ServerStoragesSettingsData?.response || "{}");

               ServerStoragesSettingsResponse.advantages = ServerStoragesSettingsResponse;
               setSettingResponse(ServerStoragesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   



     const viewLargeImage = (image) => {
          setViewLargeImage(image);
          setLargeImageStatus(true)
     };


     return (

          <>
               {ServerStorage === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/dell/servers-storage' parentTitle='Server Storage' parentUrl='https://ishantechnologies.com' />
                    <MetaTag data={ServerStorage} pageKey="smart_city"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={ServerStorage} pageKey="smart_city" />
                              </div>
                         </div>
                         <div className="video hero-banner internet-banner">
                              <FrontVideo homeData={ServerStorage} pageKey="smart_city" />

                              <div className="banner-content">
                                   <div className="content-blk">
                                             {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                             </h2>
                                             )}

                                             {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {ServerStorage?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                                   <div className="form-blk">
                                        <ServerStorageSec homeData={ServerStorage} pageName="DellServerStorage" />
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgIshanLeasedLine} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: ServerStorage?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>
                                   </div>
                              </div>
                              <div className="container">

                                   <div className="empowering-right pl-list">
                                        <h4 className="fw-600 mb-5 mt-5">Ishan Your Trusted Dell APEX Partner </h4>
                                        <div className="box-impower-wrap">
                                             <div className="box-empower">
                                                  <ul className="serv-storage-list">
                                                       <li>
                                                       <span>Platinum Dell Partner</span>
                                                       <p>
                                                       A long-standing partnership with Dell, ensuring you receive expert support and industry-leading solutions.
                                                            </p> 
                                                       
                                                       </li>
                                                       <li>
                                                       <span>20+ Years of Expertise</span>
                                                       <p>
                                                            Proven track record in delivering enterprise-grade networking solutions tailored 
                                                       </p> 
                                                       </li>
                                                  </ul>
                                             </div>
                                             <div className="box-empower">
                                                  <ul className="serv-storage-list">
                                                       <li>
                                                       <span>Pan-India Presence</span>
                                                       <p>
                                                       Nationwide support to ensure seamless operations, wherever your offices are located.
                                                            </p> 
                                                       
                                                       </li>
                                                       <li>
                                                       <span>Dell Product Expertise</span>
                                                       <p>
                                                       Deep knowledge of Dell’s suite of products ensures a customized solution for your unique challenges. 
                                                       </p> 
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>
                                   
                              </div>
                              <div className="container mt-5">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgIshanLeasedLine} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div class="empowering-right">
                                             <h4>Explore Dell APEX Solutions</h4>
                                        </div>
                                   </div>
                              </div>
                              <div className="container mt-4">

                                   <div className="pl-list">
                                        <div className="box-impower-wrap">
                                             <div className="box-empower w-100">
                                                  <ul className="serv-storage-list">

                                                       <li>
                                                       <span>Dell APEX Data Storage Services</span>
                                                       <p>
                                                            Flexible, on-demand storage that eliminates overprovisioning, offering secure, scalable solutions to meet your data needs.
                                                       </p> 
                                                            
                                                       </li>

                                                       <li>
                                                       <span>Dell APEX Data Protection</span>
                                                       <p>
                                                            Ensure business continuity with scalable, high-security backup solutions designed to keep your data protected and always available.
                                                       </p> 
                                                            
                                                       </li>
                                                       <li>
                                                       <span>Dell APEX Multi-Cloud Services</span>
                                                       <p>
                                                       Manage public, private, and hybrid clouds effortlessly with subscription services that offer predictable costs and seamless integration.
                                                       </p> 
                                                       </li>
                                                       <li>
                                                       <span>Dell APEX Compute Services</span>
                                                       <p>
                                                       Scale your compute capacity dynamically to optimize performance across on-premise and multi-cloud environments.
                                                       </p> 
                                                       </li>
                                                       <li>
                                                       <span>Dell APEX Custom Solutions</span>
                                                       <p>
                                                       Tailor your IT infrastructure to your business objectives with fully customizable subscription services.
                                                       </p> 
                                                       </li>
                                                  </ul>
                                             </div>
                                        </div>
                                   </div>
                                   
                              </div>
                              <div className="container mt-5 mb-5">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgIshanLeasedLine} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div class="empowering-right">
                                             <h4>Business Value of Dell Apex</h4>
                                             <p>
                                             Dell APEX delivers significant value to organizations looking to optimize their IT operations. Here are the key numbers that highlight its impact:
                                             </p>
                                        </div>
                                   </div>
                                   <img src={BusinessValue} alt="" className="img-fluid" />
                                   <div className="mob-img text-center">
                                        <button class="button btn-advantage mt-4" onClick={(e) => { e.preventDefault(); viewLargeImage(BusinessValue); }}>View Enlarged Image</button>
                                   </div>
                              </div>
                              <div className="container mt-5">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgIshanLeasedLine} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div class="empowering-right">
                                             <h4>Unlock the full potential of multi-cloud with Dell APEX. </h4>
                                             <p>
                                             This solution enables organizations to leverage cutting-edge Dell Technologies innovations quickly and effortlessly, maximizing the benefits of multi-cloud. It drives innovation while simplifying operations, reducing inefficiencies, and cutting costs.
                                             </p>
                                        </div>
                                   </div>
                                   <img src={Potential} alt="" className=" img-fluid" />
                                   <div className="mob-img text-center">
                                        <button class="button btn-advantage mt-4" onClick={(e) => { e.preventDefault(); viewLargeImage(Potential); }}>View Enlarged Image</button>
                                   </div>
                                   
                                   <div className="dell-dwnld-details"> 
                                        <Link to={DellApexBrochure} target="_blank">
                                             To learn more about Dell Apex Report Link {<img src={DownloadIcon} alt="icon" loading="lazy"/>}
                                        </Link>
                                   </div>
                                   
                                   
                              </div>
                              
                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         {/* <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={ServerStorage?.data?.data?.smart_city?.smart_city_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={ServerStorage?.data?.data?.smart_city?.smart_city_advantages_data} action='colocation' />
                        */}


                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading" style={{marginBottom:'2px'}}>{ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h2>
                                  <div className="list_ctabtn"> 
                                        <ul> 
                                             <li style={{color: '#fff'}}>[<a href={ESGInfoGraphic} target="_blank" style={{color: '#fff'}}>Download the ESG Infographic: </a>The Economic Benefits of Dell APEX] </li>
                                             <li style={{color: '#fff'}}>[<a href={ECGMultiCloud} target="_blank" style={{color: '#fff'}}>Read the ESG eBook:  </a>Multi-Cloud Application Deployment and Delivery Decision Making] </li>
                                        </ul>
                                        <Link to='' target="_blank">Link</Link>
                                  </div>
                                   <div className="supercharge-btns">
                                        {ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                       
                                        {ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{ServerStorage?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="DellServerStorage" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); setInTuchPlanModal(false); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <ServerStorageSec homeData={ServerStorage} pageName="DellServerStorage" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getLargeImageStatus} toggle={getLargeImageStatus}>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); setLargeImageStatus(false); }}>×</Link>
                              <ModalBody className="scrolling-img">
                                   <img src={getViewLargeImage} alt="" className="w-100" loading="lazy"/>
                              </ModalBody>
                         </Modal>
                        
                         <a href="javascript:void(0)" className="enquire-now-side-btn"  onClick={(e) => { e.preventDefault(); setInTuchPlanModal(true); }}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}