import React from 'react'

const SubServiceSchema = ({ title, url, parentTitle, parentUrl }) => {

  

      
     return (
         
          <></>
         
     )
}

export default SubServiceSchema