import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";

export default function Fostering({ homeData }) {

  const [homeInfo, setHomeInfo] = useState(null);

  useEffect(() => {
    if (homeData && homeData.data && homeData.data.data && homeData.data.data.home && homeData.data.data.home.home_information_data) {
      const description = homeData.data.data.home.home_information_data[0].description;
      const sanitizedDescription = DOMPurify.sanitize(description);
      setHomeInfo(sanitizedDescription);
    }

  }, [homeData])

  return (
    <>
      {homeInfo && (
        <div className="fostering-wrap">
          <div className="container">
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homeInfo) }} ></span>
          </div>
        </div>
      )}
    </>
  );
}