import React from 'react'

const Partners = ({ title, data }) => {
     return (
          <div className="esteemed-partners section-space">
               <div className="container">
                    <h2>{title}</h2>
                    <ul className="esteemed-icon">
                         {data?.sort((a, b) => a.order - b.order)
                              .map((partner, index) => (
                                   <li>
                                        <img
                                             key={partner.id}
                                             src={partner.image}
                                             alt={partner.altText}
                                             height={44}
                                             width={173}
                                             loading="lazy"
                                        />
                                   </li>
                              ))}
                    </ul>
               </div>
          </div>
     )
}

export default Partners