import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import DownloadIcon from "../assest/images/download-icon.png";
import { Link } from "react-router-dom";

export default function DownloadsResources() {
     useEffect(() => {
          getData();
     }, [])
     const [downloadData, setDownloadData] = useState(null);
     const [metaData, setMetadata] = useState(null);
     const [bannerTitle, setBannerTitle] = useState(null);
     const [getGroupedMedia, setGroupedMedia] = useState(null);
     const getData = () => {
          axios.post(`${API_BASE_URL}/api/download/getAlldownload`).then((response) => {


               // console.log('response',response.data.data?.download?.download_data); 
               // let blog_groupings = response.data.data?.download?.download_data;
               // const groupedMedia = { videos: [], files: [], images: [] };

               // blog_groupings?.forEach((tag, index) => {
               // if (tag.video_url)
               //      groupedMedia.videos.push(
               //           { id: index, name : tag.name, thumbnail:tag.image, alt:tag.alt,video_url: tag.video_url, }
               //      );

               // if (tag.pdfFile?.endsWith('.pdf') && (tag.video_url == "" || tag.video_url == null)) 
               //      groupedMedia.files.push({ id: index, name : tag.name, thumbnail:tag.image, alt:tag.alt, pdfFile: tag.pdfFile 
               // });

               // if (tag.pdfFile && /\.(jpeg|jpg|png|gif)$/i.test(tag.pdfFile) && (tag.video_url == "" || tag.video_url == null)) 
               //      groupedMedia.images.push({ id: index, name : tag.name, thumbnail:tag.image, alt:tag.alt, image_url: tag.pdfFile });
               // });

               // console.log('groupedMedia', groupedMedia);
               // setGroupedMedia(groupedMedia);

               if (response.data.error === false) {
                    setDownloadData(response.data.data);
                    setMetadata(response);
                    const bannerTitleResponse = JSON.parse(response.data.data?.download?.banner_data[0]?.response || "{}");
                    setBannerTitle(bannerTitleResponse);
               }
          }).catch((error) => {
               <PageLoader />
          })
     }



     // const renderMediaSection = (title, items, urlKey) => (
     //          <div className="dwnld">
     //          <h2>{title}</h2>
     //              {items.length > 0 ? (
     //                  items.map((item, index) => (
     //                     <div key={index} className="media-item">
     //                     {title === 'Videos' ? (
     //                         <>
     //                          <iframe
     //                               width="320"
     //                               height="240"
     //                               src={`https://www.youtube.com/embed/${item.video_url}?si=jQ8Tix33wyIUROIQ&amp;controls=0`}
     //                               title="YouTube video player"
     //                               frameBorder="0"
     //                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
     //                               allowFullScreen
     //                          ></iframe>
     //                          <p>{item.name}</p>
     //                          <a href={`https://www.youtube.com/watch?v=${item.video_url}`} target="_blank" rel="noopener noreferrer">
     //                              View video
     //                          </a>
     //                         </>
     //                     ) : title === 'Images' ? (
     //                         <>
     //                               <img src={item.thumbnail} alt="lable" width="275" height="375" />
                              
     //                               <a href={item.image_url} target="_blank" rel="noopener noreferrer">
     //                                    <p>{item.name}</p>
     //                               </a>
     //                         </>
     //                     ) : title === 'Files' ? (
     //                         <>
     //                             <a href={item.pdfFile} target="_blank" rel="noopener noreferrer">
     //                               <img src={item.thumbnail} alt="lable" width="275" height="375" />
     //                               <p>{item.name}</p>
     //                             </a>
     //                         </>
     //                     ) : null}
     //                 </div>

     //                  ))
     //              ) : (
     //                  <p>No items found.</p>
     //              )}
     //          </div>
     //  );

     return (
          <>
          {downloadData !== null &&
          <>
               <MetaTag data={metaData} pageKey="download"/>
               <div className="page_heading responsive_heading">
                    <div className="container">
                         <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
                    </div>
               </div>
               <div className="downloadsresources md-section-space">
                    <div className="container">
                         <div className="dwnld">
                              <ul>
                                   {downloadData?.download?.download_data.map((tag, index) => (
                                        <li>
                                             {<img src={tag.image} alt={tag.altText} height={375} width={275} loading="lazy"/>}
                                             <div className="dwnld-details">
                                                  <Link to={tag.pdfFile} target="_blank">
                                                       {tag.name} {<img src={DownloadIcon} alt="icon" loading="lazy"/>}</Link>
                                             </div>
                                        </li>
                                   ))}

                              </ul>
                         </div>
                         
                         {/* <div className="downloadsresources">
                              <div id="container">
                                   {renderMediaSection("Videos", getGroupedMedia.videos, "video_url")}
                                   {renderMediaSection("Files", getGroupedMedia.files, "pdfFile")}
                                   {renderMediaSection("Images", getGroupedMedia.images, "image_url")}
                              </div>
                         </div> */}


                    </div> 
               </div>
          </>
          }
          </>
     );
}