import React, { useEffect, useState } from "react";
import FrontVideo from "../components/homevideo";
import HeaderTitle from "../components/headerTitle";
import IconBorder from "../assest/svg/icon-border";
import IconBorderBottom from "../assest/svg/icon-borderbottom";
import { API_BASE_URL } from "../config/config";
import axios from "axios";
import Information from "../components/CommonSections/Information";
import Advantage from "../components/CommonSections/Advantage";
import CtaBanner from "../components/CommonSections/CtaBanner";
import MetaTag from "../components/CommonSections/MetaTag";
import { Link } from "react-router-dom";
import PageLoader from "../components/home/pageload";
import ServiceSchema from "../components/CommonSections/serviceSchema";

export default function DataCenterServices() {
     const [activeTab, setActiveTab] = React.useState(1);
     const [activeTabSecond, setActiveTabSecond] = React.useState(1);
     const handleTabClick = (tabNumber) => {
          setActiveTab(tabNumber);
     };
     const handleTabClickSecond = (tabNumber) => {
          setActiveTabSecond(tabNumber);
     };
     const handleClick = () => {
          window.scrollTo(0, 0);
     }


     const [dataCenterServicesData, setDataCenterServicesData] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {
               service_tag: "data_centre_and_cloud_service", // Set the desired service_tag value
          };
          axios.post(`${API_BASE_URL}/api/cloudeservices/getCloudeServices`, requestData).then((response) => {

               setDataCenterServicesData(response);

               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const cybersecurityServicesData = generalSettingsData.find((item) => item.tag === "data_centre_and_cloud_service_advantage") || null;
               const cybersecurityResponse = JSON.parse(cybersecurityServicesData?.response || "{}");
               cybersecurityResponse.advantages = cybersecurityResponse;
               setSettingResponse(cybersecurityResponse);

          }).catch((error) => {
               <PageLoader />
          })
     }, [])

     return (

          <>
               {dataCenterServicesData === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <ServiceSchema title={dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                              [0].title} url='https://ishantechnologies.com/data-center-services' />
                     <MetaTag data={dataCenterServicesData} pageKey="cloude_services"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={dataCenterServicesData} pageKey="cloude_services" />
                              </div>
                         </div>
                         <div className="video hero-banner" >
                              <FrontVideo homeData={dataCenterServicesData} pageKey="cloude_services" />
                              <div className="banner-content ">
                              
                              <div className="banner-content ">
                              {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0]?.banner_title && (
                              <h2 className='home-heading'>
                                   {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0].banner_title}
                              </h2>
                              )}

                              {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0]?.banner_img_desc && (
                              <p className="para">
                                   {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0].banner_img_desc}
                              </p>
                              )}

                              {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0]?.button_img_name && (
                              <a href={dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0].button_url}>
                                   <button className="button hero-btn">
                                   {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_banner_data
                                   [0]?.button_img_name}
                                   </button>
                              </a>
                              )}
                              </div>  
                              </div>
                         </div>

                         <Information homeData={dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_information_data[0]?.description} />

                         <div className="our-capabilities section-space">
                              <div className="container">
                                   <h2>Key Highlights</h2>
                                   <div className="capabilities-inner">
                                        <div className="capabil-frow">
                                             {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_key_highlights_data.slice(0, 3).map((highlight, index) => (
                                                  <React.Fragment key={index}>
                                                       <h4>{highlight.title}</h4>
                                                       {index !== 2 && <IconBorder className="iconborder" />}
                                                  </React.Fragment>
                                             ))}
                                        </div>
                                        <IconBorderBottom className="iconborderbottom" />
                                        <div className="capabil-srow">
                                             {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_key_highlights_data.slice(3).map((highlight, index) => (
                                                  <React.Fragment key={index}>
                                                       <h4>{highlight.title}</h4>
                                                       {index % 2 === 0 && <IconBorder className="iconborder" />}
                                                  </React.Fragment>
                                             ))}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer weoffer-bglight section-space">
                              <div className="container">
                                   <h2 className="weoffer-heading">Data Center Services</h2>
                                   <span dangerouslySetInnerHTML={{ __html: dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_dcs_information_data[0]?.description }} ></span>
                                   {/* <h2 className="weoffer-heading">{settingResponse?.what_we_offer_dcs}</h2> */}
                                   <h2 className="weoffer-heading">What We Offer</h2>
                                   <div className="weoffer-inner">
                                        <div className="weoffer-inner-tab">
                                             <ul className="tabs">

                                                  {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_dcs_offer_data.map((offer, index) => (

                                                       <li key={index} className={activeTab === index + 1 ? "actived tab-itme-bglight" : "tab-itme-bglight"}>
                                                            <button
                                                                 className={activeTab === index + 1 ? "tab-btn active" : "tab-btn"}
                                                                 onClick={() => handleTabClick(index + 1)}
                                                                 tabIndex={0}
                                                            >
                                                                 {offer.tag_title}
                                                            </button>
                                                       </li>

                                                  ))}
                                             </ul>
                                        </div>
                                        <div className="weoffer-details">
                                             <div className="tab-content tab-content-bglight">


                                                  {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_dcs_offer_data.map((offer, index) => (
                                                       <React.Fragment key={index}>
                                                            {activeTab === index + 1 &&
                                                                 <div className="sec-row" key={index}>
                                                                      <div className="sec-col rgt-col">
                                                                           {<img src={offer.image} alt={offer.altText} loading="lazy" height={351} width={551}/>}
                                                                      </div>
                                                                      <div className="sec-col lft-col">
                                                                           {offer.icon !== null && offer.icon !== "" &&
                                                                                <div className="svg-col">
                                                                                     <img
                                                                                          src={offer.icon}
                                                                                          width={59}
                                                                                          height={60}
                                                                                          alt={offer.iconAltText}
                                                                                          loading="lazy"
                                                                                     />
                                                                                </div>
                                                                           }
                                                                           <div className="cntnt-col">
                                                                                <h2>{offer.tag_title}</h2>
                                                                                <div dangerouslySetInnerHTML={{ __html: offer.description }} />
                                                                                {offer.button_url !== null && offer.button_url !== "" &&
                                                                                     <Link to={offer.button_url} onClick={handleClick}>
                                                                                          <button className="button">Know more</button>
                                                                                     </Link>
                                                                                }
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            }
                                                       </React.Fragment>

                                                  ))}

                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="weoffer section-space">
                              <div className="container">
                                   <h2 className="weoffer-heading">Cloud Services</h2>
                                   <span dangerouslySetInnerHTML={{ __html: dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_cs_information_data[0]?.description }} ></span>

                                   {/* <h2 className="weoffer-heading">{settingResponse?.what_we_offer_cs}</h2> */}
                                   <h2 className="weoffer-heading">What We Offer</h2>
                                   <div className="weoffer-inner">
                                        <div className="weoffer-inner-tab">
                                             <ul className="tabs">
                                                  {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_cs_offer_data.map((offer, index) => (
                                                       <li key={index} className={activeTabSecond === index + 1 ? "actived" : ""}>
                                                            <button
                                                                 className={activeTabSecond === index + 1 ? "tab-btn active" : "tab-btn"}
                                                                 onClick={() => handleTabClickSecond(index + 1)}
                                                                 tabIndex={0}
                                                            >
                                                                 {offer.tag_title}
                                                            </button>
                                                       </li>
                                                  ))}
                                             </ul>
                                        </div>
                                        <div className="weoffer-details">
                                             <div className="tab-content">
                                                  {dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_cs_offer_data.map((offer, index) => (
                                                       <React.Fragment key={index}>
                                                            {activeTabSecond === index + 1 &&
                                                                 <div className="sec-row" key={index}>
                                                                      <div className="sec-col rgt-col">
                                                                           {<img src={offer.image} alt={offer.altText} 
                                                                           height={351} width={551} loading="lazy"/>}
                                                                      </div>
                                                                      <div className="sec-col lft-col">
                                                                           {offer.icon !== null && offer.icon !== "" &&
                                                                                <div className="svg-col">
                                                                                     <img
                                                                                          src={offer.icon}
                                                                                          width={59}
                                                                                          height={60}
                                                                                          alt={offer.iconAltText}
                                                                                          loading="lazy"
                                                                                     />
                                                                                </div>
                                                                           }
                                                                           <div className="cntnt-col">
                                                                                <h2>{offer.tag_title}</h2>
                                                                                <div dangerouslySetInnerHTML={{ __html: offer.description }} />
                                                                                {offer.button_url !== null && offer.button_url !== "" &&
                                                                                     <Link to={offer.button_url} onClick={handleClick}>
                                                                                          <button className="button">Know more</button>
                                                                                     </Link>
                                                                                }
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            }
                                                       </React.Fragment>
                                                  ))}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <Advantage title={settingResponse?.advantages} data={dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_advantages_data} />
                         <CtaBanner bannerData={dataCenterServicesData?.data?.data?.cloude_services?.cloude_services_cta_banner_data} />
                    </>
               )}

          </>
     );
}