const API_BASE_URL = "https://ishantechnologies.com";
//const API_BASE_URL = "http://localhost:8001";
//const API_BASE_URL = "https://ishan.projectdemo.company";
//const API_BASE_URL = "http://103.180.186.219";

const CRM_URL = "https://wapbot.ishancloud.com/ishan-web/api_v2.php"
const SITE_KEY = "6LcTtOIoAAAAAAN5IAfi6EfkAnFqWE1tp9wIm2WG"

const FORM_AUTH_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2lzaGFudGVjaG5vbG9naWVzLmNvbS8iLCJhdWQiOiJpbnF1aXJ5LWZvcm1zIiwiaWF0IjoxNzI4MjgzNjkzLCJuYmYiOjE3MjgyODM2OTQsImV4cCI6MTcyODI4NTQ5MywiZGF0YSI6eyJfbmFtZSI6ImlzaGFuQHRlY2hub2xvZ2llcyJ9fQ.zSbAigQgzo9BZiU6kFngfnBj19PcZDjJnRDHawMxB_g"
export {
    API_BASE_URL, 
    CRM_URL,
    SITE_KEY,
    FORM_AUTH_TOKEN
}
      