import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Services = ({ homeData }) => {

  const [activeTab, setActiveTab] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [servicesIconData, setServicesIconData] = useState([]);
  const [generalServicesData, setGenaralServicesData] = useState({});

  const homeServiceRef = useRef(null);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    scrollToRef(homeServiceRef)
  };

  function scrollToRef(ref) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {

    if (homeData && homeData.data && homeData.data.data && homeData.data.data.home && homeData.data.data.home.home_services_system_data) {
      const system_service_data =
        homeData.data.data.home.home_services_system_data;
      const system_service_icon_data =
        homeData.data.data.home.home_services_icon_data;
      const general_setting_data =
        homeData.data.data.general_settings.general_settings_data;
      
      setServicesData(system_service_data);
      setServicesIconData(system_service_icon_data);

      const serviceDataInfo = general_setting_data.find(
        (item) => item.tag === "home_services"
      );

      if (serviceDataInfo) {
        const parsedData = JSON.parse(serviceDataInfo.response);
        setGenaralServicesData(parsedData);

        if (system_service_data.length > 0) {
          setActiveTab(system_service_data[0].home_services_icon_id);
        }
      }

    }
  }, [homeData]);

  return (
    <>
      {generalServicesData.title && (
        <div className="service">
          <div className="container">
            <div className="heading">
              <h2>
                {generalServicesData?.title} <span>{generalServicesData?.sub_title}<span className="fontchange">{generalServicesData?.italic_title}</span></span>
              </h2>
              <span className="sub-description">{generalServicesData.description}</span>
            </div>
            <ul className="tabs">
              {servicesIconData.map((serviceIcon) => (
                <li key={serviceIcon.id}>
                  <button className={activeTab === serviceIcon.id ? "tab-btn active" : "tab-btn"} onClick={() => handleTabClick(serviceIcon.id)} tabIndex={0}>
                    <figure className="icon">
                      {<img src={serviceIcon?.icon_image} alt={serviceIcon.altText} height={84} width={84} loading="lazy"/>}
                    </figure>
                    <span className="title">{serviceIcon.icon_title}</span>
                    <span className="arrow"></span>
                  </button>
                </li>

              ))}
            </ul>
          </div>
        </div>
      )}

      <div className="tab-content section-space home-service" ref={homeServiceRef}>
        <div className="container">
          {servicesData.map((service, index) => (
            <div key={service.id}>
              {activeTab === service.home_services_icon_id &&
                <div className="sec-row" >
                  <div className="sec-col lft-col">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service.service_description,
                      }}
                    />
                    {service.button_url !== null && service.button_url !== "" &&
                      <Link className="button" to={service.button_url} onClick={handleClick}>
                        Know more
                      </Link>
                    }
                  </div>
                  <div className="sec-col rgt-col">
                    {<img src={service.service_image} alt={service.altText} height={351} width={551} loading="lazy"/>}
                  </div>
                </div>
              }
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Services;