import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default function FormsLinks({ slug }) {

  var settings = {
    infinite: true,
    arrows: true,
    speed: 800,
    autoplay: false,
    autoplaySpeed: 1000,
    variableWidth: true,
  };

  return (
    <>
      <div className="forms-links">
        <div className="container">
          <Slider {...settings}>
            <Link to="/contact/contact-for-sales" className={`link-action ${slug === "contact-for-sales" ? "active" : ""}`}>Contact for Sales</Link>
            <Link to="/contact/contact-for-customer-service" className={`link-action ${slug === "contact-for-customer-service" ? "active" : ""}`}>Customer Service</Link>
            <Link to="/contact/partner-with-us" className={`link-action ${slug === "partner-with-us" ? "active" : ""}`}>Partner with us</Link>
            <Link to="/contact/media-inquires" className={`link-action ${slug === "media-inquires" ? "active" : ""}`}>Media Inquiry</Link>
            <Link to="https://www.linkedin.com/jobs/search/?currentJobId=3733672216&f_C=1175864&geoId=92000000&keywords=Ishan%20Technologies%20&location=Worldwide&origin=JOB_SEARCH_PAGE_JOB_FILTER&refresh=true" target="_blank" className={`link-action ${slug === "careers" ? "active" : ""}`}>Careers</Link>
          </Slider>
        </div>
      </div>
    </>
  );
}
