import './App.scss';
// Import Routes all
import React from "react";
import MyRouter from "./routes/Routes";
import { BrowserRouter } from 'react-router-dom';
import AppModal from '../src/components/popup';

function App() {
  return (
    <>
     <BrowserRouter>
     <MyRouter />
     </BrowserRouter>
     <div>
      {/* Other content of your app */}
      <AppModal />
    </div>
    </>
  );
}

export default App;
