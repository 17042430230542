import React, { useEffect, useState } from "react";
import PageLoader from "../components/home/pageload";
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import MetaTag from "../components/CommonSections/MetaTag";

export default function Location() {

  const [locationData, setLocationData] = useState(null);
  const [metaData, setMetadata] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);
  const [settingBannerResponse, setSettingBannerResponse] = useState(null);

  useEffect(() => {
    axios.post(`${API_BASE_URL}/api/locateus/getAllLocateUsZone`).then((response) => {

      setLocationData(response?.data?.data);
      setMetadata(response);
      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const locationSettingsData = generalSettingsData.find((item) => item.tag === "zone") || null;
      const locationSettingsResponse = JSON.parse(locationSettingsData?.response || "{}");
      setSettingResponse(locationSettingsResponse);
      const locationBannerSettingsData = generalSettingsData.find((item) => item.tag === "location_banner") || null;
      const locationBannerSettingsResponse = JSON.parse(locationBannerSettingsData?.response || "{}");
      setSettingBannerResponse(locationBannerSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }, [])

  return (
    <>

      {locationData === null ? (
        <PageLoader />
      ) : (
        <>
          <MetaTag data={metaData} pageKey="locate_us"/>
          <div className="page_heading">
            <div className="container">
               {settingBannerResponse?.title != "" && 
                                   <h1>{settingBannerResponse?.title}</h1>
                                   }
                                   {settingBannerResponse?.sub_title != "" && 
                                   <p>{settingBannerResponse?.sub_title}
                                        <span>{settingBannerResponse?.italic}</span>
                                   </p>
                                   }
            </div>
          </div>
          <div className="cms location_page spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
            <div className="container">
              <div className="breadcrumb">
                <ul>
                  <li>Support</li>
                  <li>Locate Us</li>
                </ul>
              </div>
              <h2>{settingResponse?.title}</h2>
              <p className="description">{settingResponse?.description}</p>
              <div className="location_wrapper">

                {locationData?.locate_us?.locate_us_zones_data.map((zone) => (
                  <div className="location_box" key={zone.id}>
                    <h2>{zone.zone}</h2>
                    <ul>
                      {locationData?.locate_us?.locate_us_data
                        .filter((location) => location.locate_us_zone_id === zone.id)
                        .map((location) => (
                          <li key={location.id}>
                            <h3>{location.title}</h3>
                            <address>{location.description}</address>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}

              </div>
            </div>
            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}