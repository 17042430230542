import React from 'react';

const PageLoader = () => {
  return (
    <div className="page-loader spin-wrap">
      <div className="spin-round-wrap">
        <div className="spin-round color-navy-blue">
          <span className='ballDot1'>1</span>
          <span className='ballDot2'>2</span>
          <span className='ballDot3'>3</span>
          <span className='ballDot4'>4</span>
        </div>
      </div>
      <div className="loader"></div>
      <div className="snippet" data-title="dot-gathering">
        <div className="stage filter-contrast">
          <div className="dot-gathering"></div>
        </div>
      </div>
      <div className="spin-round-wrap">
        <div className="spin-round">
          <span className='ballDot1'>1</span>
          <span className='ballDot2'>2</span>
          <span className='ballDot3'>3</span>
          <span className='ballDot4'>4</span>
        </div>
      </div>
    </div>
  );
};
export default PageLoader;
