import React, { useEffect, useState } from 'react';
import FrontVideo from "../components/homevideo";
import IconBorderBottom from "../assest/svg/icon-borderbottom";
import Slider from "react-slick";
import axios from 'axios';
import { API_BASE_URL } from '../config/config';
import PageLoader from '../components/home/pageload';
import Information from '../components/CommonSections/Information';
import MetaTag from "../components/CommonSections/MetaTag";
import { Link } from "react-router-dom";

export default function AboutUs() {


  const [aboutData, setAboutData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const [recognizeData, setRecognizeData] = useState(null);

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://ishantechnologies.com/about-us",
    "logo": "https://ishantechnologies.com/api/static/13924ishan_logo_png.png",
    "name": "Ishan Technologies",
    "description": "Learn about Ishan Technologies, a leading provider of digital infrastructure solutions and ICT services. Explore our ICT managed services and digital platforms. Join us!",
    "email": "helpdesk@ishantechnologies.com",
    "telephone": "+91 28166-47426",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "B-11, Privilon, Iskcon Cross Road, Ambli - Bopal Rd,",
      "addressLocality": "Ahmedabad",
      "addressCountry": "India",
      "addressRegion": "Gujarat",
      "postalCode": "380054"
    }
  };

  useEffect(() => {

    const data = {
      "service_tag": "about"
    }

    axios.post(`${API_BASE_URL}/api/aboutus/getAboutUs`, data).then((response) => {

      setAboutData(response);
     
      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
         const about_team = generalSettingsData.find((item) => item.tag === "about_team") || null;
         const about_certificates = generalSettingsData.find((item) => item.tag === "about_certificates") || null;
         const about_recognitions = generalSettingsData.find((item) => item.tag === "about_recognitions") || null;

         const teamResponse = JSON.parse(about_team?.response || "{}");
         const certificateResponse = JSON.parse(about_certificates?.response || "{}");
         const recognizeResponse = JSON.parse(about_recognitions?.response || "{}");
         
         setTeamData(teamResponse);
         setCertificateData(certificateResponse);
         setRecognizeData(recognizeResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }, [])


  // Slider START
  var settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    pauseOnHover: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  // Slider END

  return (
    <>
      {aboutData === null ? (
        <PageLoader />
      ) : (
        <>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
        <MetaTag data={aboutData} pageKey="about_us"/>
          <div className="page_heading">
            <div className="container">
              <h1>{aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.title}</h1><p>{aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.sub_title}<span>{aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.italic}</span></p>
            </div>
          </div>

          {/* Banner Video/Image START */}
          <div className="video hero-banner">
            <FrontVideo homeData={aboutData} pageKey="about_us" />

            <div className="banner-content ">
              {aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.banner_title && (
              <h2 className='home-heading'>
                    {aboutData?.data?.data?.about_us?.about_us_banner_data[0].banner_title}
              </h2>
              )}

              {aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.banner_img_desc && (
              <p className="para">
                    {aboutData?.data?.data?.about_us?.about_us_banner_data[0].banner_img_desc}
              </p>
              )}

              {aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.button_img_name && (
              <a href={aboutData?.data?.data?.about_us?.about_us_banner_data[0].button_url}>
                    <button className="button hero-btn">
                    {aboutData?.data?.data?.about_us?.about_us_banner_data[0]?.button_img_name}
                    </button>
              </a>
              )}
            </div> 

          </div>
          {/* Banner Video/Image END */}

          {/* Leading Section START */}
          <Information homeData={aboutData?.data?.data?.about_us?.about_us_information_data[0]?.description} classData={"full-width align-left"} />
          {/* Leading Section END */}

          {/* Capabilities Section START */}
          <div className="our-capabilities section-space about">
            <div className="container">
              <h2>Displaying our capabilities with our credentials</h2>
              <div className="capabilities-inner">
                <div className="capabil-frow">
                  {aboutData?.data?.data?.about_us?.about_us_service_capability_data.slice(0, 3).map((item) => (
                    <div key={item.id} className="item">
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  ))}
                </div>
                <IconBorderBottom className="iconborderbottom" />
                <div className="capabil-frow">
                  {aboutData?.data?.data?.about_us?.about_us_service_capability_data.slice(3).map((item) => (
                    <div key={item.id} className="item">
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* Capabilities Section END */}

          {/* leadership team Section START */}
          <div className="leadership section-space spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
            <div className="container">
              <h2>{teamData?.title}</h2>
              <div className="leadership-wrap">
                {aboutData?.data?.data?.about_us?.about_us_team_data.map((teamMember) => (
                  <div className="item" key={teamMember.id}>
                    <div className="img">
                      <figure>
                        <img src={teamMember.image} alt={teamMember.altText} height={270} width={270} loading="lazy"/>
                      </figure>
                      <div className="name">
                        <span>{teamMember.name}</span>
                        <span className="position">{teamMember.position}</span>
                      </div>
                    </div>
                    <div className="content">
                      <div
                        dangerouslySetInnerHTML={{ __html: teamMember.description }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="team-members">
                {aboutData?.data?.data?.about_us?.about_us_team_members_data.map((member) => (
                  <div className="item-member" key={member.id}>
                    <figure>
                      <img src={member?.image} alt={member?.altText} height={160} width={160} loading="lazy"/>
                    </figure>
                    <div className="name">
                      <span>{member.name}</span>
                      <span className="position">{member.position}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>
          {/* leadership team Section END */}

          {/* Fostering Section START */}
          <div className="fostering section-space">
            <div className="container">
              <h2>{aboutData?.data?.data?.about_us?.about_us_graphic_data[0]?.title}</h2>
              <span className="description">{aboutData?.data?.data?.about_us?.about_us_graphic_data[0]?.sub_title}</span>
              <figure className="bg-static">
                <img src={aboutData?.data?.data?.about_us?.about_us_graphic_data[0]?.image} alt={aboutData?.data?.data?.about_us?.about_us_graphic_data[0]?.altText} height={182} width={1115} loading="lazy"/>
              </figure>

            </div>
          </div>
          {/* Fostering Section END */}

          {/* Certification Section START */}
          <div className="certification section-space blue-bg">
            <div className="container">
              <h2>{certificateData?.title} <span>{certificateData?.sub_title}<span className="fontchange">{certificateData?.italic}</span></span></h2>
              <div className="certificate-wrap">
                {aboutData?.data?.data?.about_us?.about_us_certificate_data?.map((certificate_data) => (
                  <div className="certi-item">
                    <figure>
                      <img src={certificate_data.image} alt={certificate_data.altText} height={175} width={175} loading="lazy"/>
                    </figure>
                    <span className="certi-name">{certificate_data.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Certification Section END */}

          {/* Rewards Section START */}
          <div className="rewards section-space">
            <div className="container">
              <h2>{recognizeData?.title} <span>{recognizeData?.sub_title}<span className="fontchange">{recognizeData?.italic}</span></span></h2>
              <div className="reward-item-wrap">
                <Slider {...settings}>
                  {aboutData?.data?.data?.about_us?.about_us_recognitions_data?.map((recognitions_data, index) => (
                    <div className="ward-item">
                      <figure>
                        <img src={recognitions_data.image} alt={recognitions_data.altText} height={170} width={181} loading="lazy"/>
                      </figure>
                      <span className="reward-name">{recognitions_data.title}</span>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* Rewards Section END */}

          <div className="supercharge supercharge-left">
            <div className="container">
              <h2 className="supercharge-heading">{aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.title}</h2>

              <div className="supercharge-btns">
                {aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_title_1 &&
                  <Link to={aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_url_1}>
                    <button className="getintouch" onClick={handleClick}>{aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                  </Link>
                }
                {aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_title_2 &&
                  <Link to={aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_url_2}>
                    <button className="getintouch" onClick={handleClick}>{aboutData?.data?.data?.about_us?.about_us_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                  </Link>
                }
              </div>

            </div>
          </div>
        </>
      )}
    </>
  );
}