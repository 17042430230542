import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";

export default function OurQualityPolicy() {

  useEffect(() => {
    getData();
  }, [])

  const [qualityPolicyData, setQualityPolicyData] = useState(null);
  const [metaData, setMetadata] = useState(null);
  const [bannerTitle, setBannerTitle] = useState(null);

  const getData = () => {
    const requestData = {
      "service_tag": "quality_policy"
    };
    axios.post(`${API_BASE_URL}/api/policy/getQualityPolicy`, requestData).then((response) => {

      if (response.data.error === false) {
        setQualityPolicyData(response.data.data);
        setMetadata(response);
        const bannerTitleResponse = JSON.parse(response.data.data?.quality_policy?.quality_policy_banner_data[0]?.response || "{}");

        setBannerTitle(bannerTitleResponse);


      }
    }).catch((error) => {
      <PageLoader />
    })
  }
  return (
    <>
      {qualityPolicyData &&
        <>
          <MetaTag data={metaData} pageKey="quality_policy" />
          <div className="page_heading responsive_heading">
            <div className="container">
              <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
            </div>
          </div>

          <div className="our-customer md-section-space spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>

            <div className="container">
              <div dangerouslySetInnerHTML={{ __html: qualityPolicyData?.quality_policy?.quality_policy_information_data[0]?.description }}></div>

              <ul className="esteemed-icon">
                {qualityPolicyData?.quality_policy?.quality_policy_image_data.map((imageData, index) => (
                  <li>
                    <img src={imageData?.image} alt={imageData?.altText} height={92} width={272} loading="lazy"/>
                    <h5>{imageData?.title}</h5>
                  </li>
                ))}
              </ul>
            </div>
            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}