import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CareersSlick from '../../src/components/slick.js/careers-slick';
import LifeAtIshan from '../../src/components/slick.js/life-at-ishan-slick';
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";
import axios from "axios";

export default function Careers() {

     const [careerData, setCareerData] = useState(null);
     const [metaData, setMetadata] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     useEffect(() => {
          axios.post(`${API_BASE_URL}/api/career/getCarriers`).then((response) => {


               setCareerData(response?.data?.data);
               setMetadata(response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

               const networkServicesSettingsData = generalSettingsData.find((item) => item.tag === "careers_banner") || null;
               const networkServicesSettingsResponse = JSON.parse(networkServicesSettingsData?.response || "{}");
               setSettingResponse(networkServicesSettingsResponse);
          }).catch((error) => {
               <PageLoader />
          })
     }, [])

     return (
          <>
          <MetaTag data={metaData} pageKey="careers"/>
               <div className="page_heading responsive_heading">
                    <div className="container">
                         <h1>{settingResponse?.title}</h1><p>{settingResponse?.sub_title}<span>{settingResponse?.italic}</span></p>
                    </div>
               </div>
               <div className="our-team sml-section-space">
                    <div className="container forcls">
                         <p className="breadcrumb"><i>Who we are / Careers</i></p>
                         <h2>Igniting your potential with<br></br>
                              innovation and collaboration</h2>
                    </div>
                    <div className="sec-our-team section-space">
                         <div className="container">
                              <h2>{careerData?.careers?.career_our_team_data[0]?.title}</h2>
                              <p>{careerData?.careers?.career_our_team_data[0]?.description}
                              </p>
                              <div className="our-points">
                                   <div className="our-points-left">
                                        {<img src={careerData?.careers?.career_our_team_data[0]?.image} alt={careerData?.careers?.career_our_team_data[0]?.altText} height={301} width={535} loading="lazy"/>}
                                   </div>
                                   <div className="our-points-right" dangerouslySetInnerHTML={{ __html: careerData?.careers?.career_our_team_data[0]?.points }} />
                              </div>
                         </div>
                    </div>

                    <div className="sec-our-culture section-space">
                         <div className="container">
                              <h2>{careerData?.careers?.career_our_culture_data[0]?.title}</h2>
                              <p>{careerData?.careers?.career_our_culture_data[0]?.description}</p>
                              <div className="our-points">
                                   <div className="our-points-right" dangerouslySetInnerHTML={{ __html: careerData?.careers?.career_our_culture_data[0]?.points }} />
                                   <div className="our-points-left">
                                        <CareersSlick careerData={careerData?.careers?.career_our_culture_images_data} />
                                   </div>

                              </div>
                         </div>
                    </div>

                    <div className="supercharge careers-join-us md-section-space supercharge-left">
                         <div className="container">
                              <div className="supercharge-btns">
                                   <Link to={careerData?.careers?.career_join_us_data[0]?.button_url} target="_blank">
                                        <button className="" onClick={handleClick} target="_blank">{careerData?.careers?.career_join_us_data[0]?.button_title}</button>
                                   </Link>
                              </div>
                              <p>{careerData?.careers?.career_join_us_data[0]?.description}</p>
                         </div>
                    </div>

                    <div className="our-values section-space">
                         <div className="container">
                              <h2>{careerData?.careers?.career_our_values_data[0]?.title}</h2>
                              {<img src={careerData?.careers?.career_our_values_data[0]?.image} alt={careerData?.careers?.career_our_values_data[0]?.altText} height={184} width={1117} loading="lazy"/>}
                              <div dangerouslySetInnerHTML={{ __html: careerData?.careers?.career_our_values_data[0]?.description }}></div>

                         </div>
                    </div>

                    <div className="life-at-ishan section-space">
                         <div className="container">
                              <h2>Life at Ishan</h2>
                              <div className="ishantec">
                                   <LifeAtIshan careerData={careerData?.careers?.career_our_life_at_ishan_data} />
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}