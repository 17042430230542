import * as React from "react"
const IconBorderBottom = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    width={958}
    height={4}
    {...props}
  >
    <image
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAA74AAAAECAQAAAADzisEAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnCQ8KOwitGBFhAAADVUlEQVRYw+2Y25KiMBCGv04QxMPM+z/meEQhyV4YIkgCOOXMVu3634hFn9IJ/adbHE9C0AyVTHi7GLwVHLV/zsgi2kKDQQDI/e9QwvrnnGV47sN6K5YFa1JLa6UcsCZL2BJsWJUlZzmaE9OxsmCbsNmiG5lhxTp4mqtTspnwcdfraloK1rP0Yn5brFnOiHi+B+Ejei5+Cob1rJy/8VtQHDijf9HfmQPqZfYE2EVPlDxh5TqISWbpK6oHzWktTcWhZ39cR3Hg2vmnR7/XM3WIR6MSsooLZwTBAVkiAsFwDP+yhIzjGLwsElHVgWUW3uejnztPqcj7OwcNT6pgnq1gGTk5FnCe1GyC3EqfzBVF5JDdEmxZsh7oOsSnzLKijB7R+wZto5+EQ4UFFxEfePvW/+Z8PNDOPUld8v1gMUG+AhgKNqN57CZ+mnwtTThoc4mg7jwbSrYzyaPBdg61JZ9YyVB/mMWSYib1P67bRj4wB2xeRr5uRmSGFeW3VvDGz0BxpJpBvuopMhuzU3GM+HPIN30Ih0RdnI9H8nUsksTV99K/SszR01Tsg45DT2S/T74yIb3nioZAqvFY+nHrhJymZofzu6ITzZnjy2s7TJDu48QFheBooiusOdBW/GGlUpw5oXptWTfKCycALOdBdELFFYVQUSOA4pqhPf0YCkoshjJKBi0pLqN0taHAYClYDkjPoflEPDnHyrZlE0h5FU2bYR16T528dS195A6VuCE9do1Z8pBacrZhO9P22vjzznrNxOe7eFEJeeONfwXF3w7gv8d19K1Ddei97hB3DO2VX3DsO11wH4LxbchtchCXE6wnS8Gxi16ab4R6q9a2Q9V9XKgRFIZdhFyFhjM3qtxzGcSiuFIhKGr2A9uKpT/DZsAVgqBQaD8j1YCikd+b9fkUpaBD6lJol2STMm3PHr+btNoFZYijSWwTWLIwuh6zd5Nd9RI+ldS8NzR2yRju9jXbh2vCnI1zwLZ3WZLEPCBtYZMc4jyPgs0PD3stGZ8/6uGNvwXB8hWdnbzSx72DegUOXJ/ofaVXIW8kMFe/mxXFnstEb+p6I2dF5fu2eR4aTqNxtZNMwXGiSch255lwTDYtbZ/dHy4PfYq3mq4xN5aZqoHywhMwgj+vKkF2NzI2bAAAAABJRU5ErkJggg=="
      width={958}
      height={4}
    />
  </svg>
)
export default IconBorderBottom