import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useCookies } from 'react-cookie';
import IconClose from '../assest/svg/icon-close';
import { API_BASE_URL } from "../config/config";
import axios from "axios";

const AppModal = () => {
  const [popupData, setPopupData] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [cookies, setCookie] = useCookies(['modalClosed']);

  useEffect(() => {
    axios.post(`${API_BASE_URL}/api/popup/getPopUpData`)
      .then((response) => {
        if (response.data.error === false) {
          const fetchedPopupData = response.data.data[0];
          if (fetchedPopupData.is_popup === 1 && !cookies.modalClosed) {
            setPopupData(fetchedPopupData);
          } else {
            setIsOpen(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookies.modalClosed]);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setCookie('modalClosed', true, { maxAge: 604800 }); // Cookie expires after a week (604800 seconds)
    }, 450);
  };

  const handleImageClick = () => {
    setCookie('modalClosed', true, { maxAge: 604800 }); // Set the cookie when the user clicks on the image
    closeModal();
  };

  return (
    <>
      {popupData && isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className={`custom-modal ${isClosing ? 'closing' : ''}`}
        >
          <button className="close-btn" onClick={closeModal}>
            <IconClose />
          </button>
          <a
            className="modal-img"
            href={popupData.url}
            onClick={handleImageClick}
          >
            <img
              src={popupData.image}
              width={700}
              height={700}
              alt={popupData.popupdata}
            />
          </a>
        </Modal>
      )}
    </>
  );
};

export default AppModal;
