import * as React from "react"
const IconBorder = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    width={4}
    height={160}
    {...props}
  >
    <image
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAACgCAQAAACBdQUNAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfnCQ8KKjDWw4rvAAABl0lEQVQ4y9WU0ZaaQBBELz2AIKI5J///j1lAUGFm8lD2rrJJ3vN26a7qqQYdMjnkNmNATQcGHPkpKDkJWm8F+l2l5uLio7eershZcKDetVa391QC88nRWxdKQXZXpnFXK0heibSYAWcySVEbggELgaNpGaIpOhgrC9AZRgEEo+MHsOkZKiNyB1qjopar5QRk5YFKp8PJMAKQjDNn1wCYkYnA2UhEVY500giSkdiA2gf2RiRJ09AIenrFSCS5sp+uPLW3MCoqnq/26fJAe+jc3nglvmkKwYWTwOQ0MvmPA+Pb5DdIf4/xH0HxbZ3h2+7FiysQBFdmwY37vyd/vvnRK9tL68FDMDHtPsqLPciu1mpsbIAZA4NrMhCMAgPuRkEpVybtxNF/vQUBWIxABVyNhVmtT1D47SXGxqo5M6M21ZxolBx8ziT7jauClTTAbCQKJVz4xfOvh/aCAws3Away9tqIgopRsHAX3Hj41XQVTCyCB7eda3RxZnYY3LX69TX6zTYJ7j55c/Hi9viVZ/BDJ4cPIJMtN5nfRniXZo2BNpgAAAAASUVORK5CYII="
      width={4}
      height={160}
    />
  </svg>
)
export default IconBorder
