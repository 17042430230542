import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function UpcomingEventsSlick({ data }) {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199.98,
                settings: {
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 991, // tablet breakpoint
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 575, // mobile breakpoint
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const handleClick = () => {
        window.scrollTo(0, 0);
    }
    return (
        <Slider {...settings}>
            {data.map((event) => (
                <div className="previous-inner" key={event.id}>
                    <div className="event-dtls">
                        <div className="event-left">
                            {<img src={event.image} alt={event.altText} width={202} height={198} loading="lazy"/>}
                        </div>
                        <div className="event-right">
                            <p>{event.title}</p>
                            <Link to={`/events${event?.button_url}`}>
                                <button to={event.button_url} className="button btn-midnight-blue" onClick={handleClick}>Know more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
}

export default UpcomingEventsSlick;