import React, { useEffect, useState } from "react";
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import ClientExperiences from "../../components/home/client-experiences";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import PageLoader from "../../components/home/pageload";
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgSmartCity from "../../assest/images/IshanSmartCity.png";
import ImgDedicatedLeasedLine from "../../assest/images/dedicated-leased-line-ic.svg";
import ImgOfficeInternet from "../../assest/images/office-internet-ic.svg";
import ImgIshanLeasedLine from "../../assest/images/ishan-leased-line-ic.svg";
import PlansPopup from "../contacts/plans-popup";

import { Link } from "react-router-dom";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";
import ColocationPopup from "../../components/colocation-popup";
import Accordion from '../../components/accordion';
import InternetServiceSec from "../../components/interner-service";
import {
     Input,
     FormFeedback,
     Form, 
     Modal,
     ModalBody,
   } from "reactstrap";

export default function TestPage() {
     const handleClick = () => {
          window.scrollTo(0, 0);
     }

     const [InternetService, setInternetService] = useState(null);
     const [settingResponse, setSettingResponse] = useState(null);

     useEffect(() => {
          const requestData = {  
               "service_tag": "internet_services"
          };

          axios.post(`${API_BASE_URL}/api/smartcity/getsmartCity`, requestData).then((response) => {
               setInternetService(response);

               console.log('response',response);
               const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
               const InternetServicesSettingsData = generalSettingsData.find((item) => item.tag === "smart_city_advantage") || null;
               const InternetServicesSettingsResponse = JSON.parse(InternetServicesSettingsData?.response || "{}");

               InternetServicesSettingsResponse.advantages = InternetServicesSettingsResponse;
               setSettingResponse(InternetServicesSettingsResponse);
          }).catch((error) => { 
               <PageLoader />
          })
     }, [])

     const [modal, setModal] = useState(false);
     const [colocationData, setcolocationData] = useState(null);
     const toggle = () => {
       if (modal) {
         setModal(false);
       } else {
         setModal(true);
       }
     };
   
   
     const openModal = (colocationData) => {
       setModal(true);
       setcolocationData(colocationData);
     };   


     const [getPlanModal, setPlanModal] = useState(false);
     const [planData, setPlanData] = useState(null);
     const planToggle = () => {
       if (getPlanModal) {
          setPlanModal(false);
       } else {
          setPlanModal(true);
       }
     };
    
   
     const openPlanModal = (planData) => {
          setPlanModal(true);
          setPlanModal(planData);
     };
   

     const [getInTuchPlanModal, setInTuchPlanModal] = useState(false);
     const planGetIntuchToggle = () => {
       if (getInTuchPlanModal) {
          setInTuchPlanModal(false);
       } else {
          setInTuchPlanModal(true);
       }
     };
    
   
     const openGetInTuchPlanModal = () => {
          setInTuchPlanModal(true);
     };
   

     return (

          <>
               {InternetService === null ? (
                    <PageLoader />
               ) : (
                    <>
                    <SubServiceSchema title={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                              [0].title} url='https://ishantechnologies.com/systems-integration/smart-city-services' parentTitle='System Integration' parentUrl='https://ishantechnologies.com/systems-integration' />
                    <MetaTag data={InternetService} pageKey="smart_city"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <HeaderTitle headerTitledata={InternetService} pageKey="smart_city" />
                              </div>
                         </div>
                         <div className="video hero-banner internet-banner">
                              <FrontVideo homeData={InternetService} pageKey="smart_city" />

                              <div className="banner-content">
                                   <div className="content-blk">
                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_title && (
                                             <h2 className='home-heading'>
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_title}
                                             </h2>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.banner_img_desc && (
                                             <p className="para">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].banner_img_desc}
                                             </p>
                                             )}

                                             {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name && (
                                             <a href={InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0].button_url}>
                                                  <button className="button hero-btn">
                                                  {InternetService?.data?.data?.smart_city?.smart_city_banner_data
                                        [0]?.button_img_name}
                                                  </button>
                                             </a>
                                             )}
                                   </div>
                                   <div className="form-blk">
                                        <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                                   
                              </div>  
                         </div>

                         <div className="empowering section-space spin-wrap">
                              <div className="spin-round-wrap">
                                   <div className="spin-round color-navy-blue">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>

                              <div className="container">
                                   <div className="empowering-inner">
                                        <div className="empowering-left">
                                             {<img src={ImgIshanLeasedLine} alt="Smart City" height={60} width={60} loading="lazy"/>}
                                        </div>
                                        <div className="empowering-right" dangerouslySetInnerHTML={{ __html: InternetService?.data?.data?.smart_city?.smart_city_information_data[0]?.description }}>
                                        </div>
                                   </div>
                                   {/* <div className="colocation_popup">
                                        <button className="button getintouch" onClick={() => openModal()}>Get In Touch</button>
                                   </div> */}
                              </div>
                              <div className="container">
                                   <h4 className="box-imp-title">Why Choose Ishan Leased Line over other Internet?</h4>
                                   <div className="box-impower-wrap">
                                        <div className="box-empower">
                                             <div className="empowering-inner">
                                                  <div className="empowering-left">
                                                       <img src={ImgDedicatedLeasedLine} alt="Office Internet" height={60} width={60} loading="lazy" />
                                                  </div>
                                                  <div className="empowering-right">
                                                       <h4>Office Broadband</h4>
                                                       <div className="emp-box border">
                                                            <ul>
                                                                 <li>Shared bandwidth across users, 
                                                                 fluctuating speeds
                                                                 </li>
                                                                 <li>Limited uptime</li>
                                                            </ul>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="box-empower">
                                             <div className="empowering-inner">
                                                  <div className="empowering-left">
                                                       <img src={ImgOfficeInternet} alt="Dedicated Leased Line" height={60} width={60} loading="lazy" />
                                                  </div>
                                                  <div className="empowering-right">
                                                       <h4>Enterprise Leased Line</h4>
                                                       <div className="emp-box ">
                                                            <ul>
                                                                 <li>Guaranteed, uninterrupted speeds     
                                                                 for every user
                                                                 </li>
                                                                 <li>99.9% uptime for mission-critical  	  business continuity
                                                                 </li>
                                                            </ul>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="spin-round-wrap">
                                   <div className="spin-round">
                                        <span className='ballDot1'>1</span>
                                        <span className='ballDot2'>2</span>
                                        <span className='ballDot3'>3</span>
                                        <span className='ballDot4'>4</span>
                                   </div>
                              </div>
                         </div>

                         <section className="plans-table-section desk_plan_sec">
                              <div className="container">
                                   <h2> Get the best Leased Line Plan for Your Business</h2>

                                   <div className="table-responsive">
                                        <table className="plans-table table-bordered">
                                             <thead>
                                                  <tr>
                                                       <th>Speed Options</th>
                                                       <th>10 Mbps</th>
                                                       <th>50 Mbps</th>
                                                       <th>100 Mbps</th>
                                                       <th>150 Mbps</th>
                                                       <th>200 Mbps</th>
                                                       <th>500 Mbps</th>
                                                       <th>1 Gbps</th>
                                                       <th>Above 1 Gbps</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  <tr>
                                                       <th>Best For</th>
                                                       <td>Small offices, SMEs</td>
                                                       <td>Medium-sized offices, file sharing</td>
                                                       <td>Remote collaboration, streaming</td>
                                                       <td>Large data transfers</td>
                                                       <td>Heavy cloud operations</td>
                                                       <td>Large-scale enterprises</td>
                                                       <td>Data-intensive businesses</td>
                                                       <td>Data-intensive businesses</td>
                                                  </tr>
                                                  <tr className="with-btn">
                                                       <th>Key Features</th>
                                                       <td>
                                                            Uninterrupted video calls, fast uploads
                                                            <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 10 Mbps plan designed for small offices and SMEs. This plan offers uninterrupted video calls and fast uploads.')}>Enquire Now</a>
                                                       </td> 
                                                       <td>Seamless file sharing, cloud storage
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 50 Mbps plan designed for Medium-sized offices and file sharing. This plan offers Seamless file sharing and cloud storage.')}>Enquire Now</a>
                                                       </td>
                                                       <td>Secure remote access, video conferencing
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 100 Mbps plan designed for Remote collaboration and streaming. This plan offers Secure remote access and video conferencing.')}>Enquire Now</a>
                                                       </td>
                                                       <td>Large video file transfer
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 150 Mbps plan designed for Large data transfers. This plan offers Large video file transfer.')}>Enquire Now</a>
                                                       </td>
                                                       <td>Real-time application performance
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 200 Mbps plan designed for Heavy cloud operations. This plan offers Real-time application performance.')}>Enquire Now</a>
                                                       </td>
                                                       <td>Backups, live streaming
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 500 Mbps plan designed for Large-scale enterprises. This plan offers Backups and live streaming.')}>Enquire Now</a>
                                                       </td>
                                                       <td>High-speed downloads, full HD video calls
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the 1 Gbps plan designed for Data-intensive businesses. This plan offers High-speed downloads and full HD video calls.')}>Enquire Now</a>
                                                       </td>
                                                       <td>High-speed downloads, full HD video calls
                                                       <a href="javascript:void(0)" className="enquire-now-btn" onClick={() => openPlanModal('I want to enquire about the above 1 Gbps plan designed for Data-intensive businesses. This plan offers High-speed downloads and full HD video calls.')}>Enquire Now</a>
                                                       </td>
                                                       
                                                  </tr>
                                             </tbody>
                                        </table>
                                   </div>

                              </div>
                         </section>
                         <section className="mob_plan_sec">
                              <div className="container">
                              <h2> Get the best Leased Line Plan for Your Business:</h2>
                                   <div class="plans-wrap">

                                        <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">10 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Uninterrupted video calls, fast uploads for &nbsp;</li>
                                                       <li>Small offices, SMEs</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 10 Mbps plan designed for small offices and SMEs. This plan offers uninterrupted video calls and fast uploads.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">50 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Seamless file sharing, cloud storage for &nbsp;</li>
                                                       <li>Medium-sized offices, file sharing</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 50 Mbps plan designed for Medium-sized offices and file sharing. This plan offers Seamless file sharing and cloud storage.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">100 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Secure remote access, video conferencing for &nbsp;</li>
                                                       <li>Remote collaboration, streaming</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 100 Mbps plan designed for Remote collaboration and streaming. This plan offers Secure remote access and video conferencing.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">150 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Large video file transfer for &nbsp;</li>
                                                       <li>Large data transfers</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 150 Mbps plan designed for Large data transfers. This plan offers Large video file transfer.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">200 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Real-time application performance for &nbsp;</li>
                                                       <li>Heavy cloud operations</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 200 Mbps plan designed for Heavy cloud operations. This plan offers Real-time application performance.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">500 Mbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>Backups, live streaming for &nbsp;</li>
                                                       <li>Large-scale enterprises</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 500 Mbps plan designed for Large-scale enterprises. This plan offers Backups and live streaming.')}>Enquire Now</button>
                                             </div>
                                             </div>
                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">1 Gbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>High-speed downloads, full HD video calls for &nbsp;</li>
                                                       <li>Data-intensive businesses</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the 1 Gbps plan designed for Data-intensive businesses. This plan offers High-speed downloads and full HD video calls.')}>Enquire Now</button>
                                             </div>
                                             </div>

                                             <div class="plan-item">
                                             <div class="plan-inner">
                                                  <span class="speed">Above 1 Gbps</span>
                                                  <div>
                                                       <ul>
                                                       <li>High-speed downloads, full HD video calls for &nbsp;</li>
                                                       <li>Data-intensive businesses</li>
                                                       </ul>
                                                  </div>
                                                  <button className="button btn-blue-eyed" onClick={() => openPlanModal('I want to enquire about the above 1 Gbps plan designed for Data-intensive businesses. This plan offers High-speed downloads and full HD video calls.')}>Enquire Now</button>
                                             </div>
                                             </div>

                                   </div>
                              </div>
                         </section>
                         <div className="weoffer-bgdark">
                              <WhatWeOffer title={settingResponse?.what_we_offer} data={InternetService?.data?.data?.smart_city?.smart_city_offer_data} />
                         </div>

                         <Advantage title={settingResponse?.advantages} data={InternetService?.data?.data?.smart_city?.smart_city_advantages_data} action='colocation' pageName="InternetLeaseLine" />
                         <div className="client-experiences section-space">
                              <ClientExperiences homeData={InternetService} pageKey="smart_city" tag="smart_city" />
                         </div>
                         
                         <div className="frequently section-space">
                              <div className="container">
                                   <h2 className="supercharge-heading">Frequently Asked Questions (FAQs)</h2>
                                   <Accordion items={InternetService?.data?.data?.smart_city?.smart_city_services_faq_data} />
                              </div>
                         </div>

                         <div className="supercharge supercharge-left">
                              <div className="container">
                                   <h2 className="supercharge-heading" style={{marginBottom:'2px'}}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.title}</h2>
                                   <p style={{ color: '#fff' }}> Ready to experience reliable connectivity with Ishan Technologies' Leased Line Services </p>
                                   <div className="supercharge-btns">
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1 &&
                                             <Link to={InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_1}>
                                                  <button className="getintouch" onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_1?.toUpperCase()}</button>
                                             </Link>
                                        }
                                       
                                        {InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2 &&
                                             <Link to={InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_url_2} target="_blank">
                                                  <button className="getintouch" onClick={handleClick}>{InternetService?.data?.data?.smart_city?.smart_city_cta_banner_data[0]?.button_title_2?.toUpperCase()}</button>
                                             </Link>
                                        }
                                   </div>
                              </div>
                         </div>
                         
                         <Modal isOpen={modal} toggle={toggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); toggle(); }}>×</Link>
                              <ColocationPopup colocationData={colocationData} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getPlanModal} toggle={planToggle}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planToggle(); }}>×</Link>
                                   <ColocationPopup colocationData={colocationData} planDetails={getPlanModal} pageName="InternetLeaseLine" />
                              </ModalBody>
                         </Modal>

                         <Modal isOpen={getInTuchPlanModal} toggle={getInTuchPlanModal}>
                              <ModalBody>
                              <Link className="close1" onClick={(e) => { e.preventDefault(); planGetIntuchToggle(); }}>×</Link>
                                   <div className="Mobile_getin_tuch">
                                      <InternetServiceSec homeData={InternetService} pageName="InternetLeaseLine" />
                                   </div>
                              </ModalBody>
                         </Modal>

                         <a href="javascript:void(0)" className="enquire-now-side-btn" onClick={() => openGetInTuchPlanModal()}>GET IN TOUCH</a>

                    </>
               )} 
          </>
     );
}