import * as React from "react"
const IconLinkdin = (props) => (
    <svg xmlns="https://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <path
        fill="#2967AE"
        fillRule="evenodd"
        d="M38.677 19.748c0 10.619-8.609 19.229-19.228 19.229C8.83 38.977.221 30.367.221 19.748.221 9.128 8.83.518 19.449.518c10.619 0 19.228 8.61 19.228 19.23Z"
        />
        <path
        fill="#FCFCFC"
        fillRule="evenodd"
        d="M20.937 17.124c.316-.355.576-.715.9-1.021.997-.94 2.177-1.414 3.554-1.402.757.006 1.505.061 2.234.272 1.669.482 2.639 1.631 3.102 3.262.346 1.222.411 2.478.413 3.737.004 2.657-.008 5.313-.002 7.969 0 .249-.069.314-.314.312a244.222 244.222 0 0 0-4.105 0c-.241.002-.293-.072-.292-.3.007-2.527.009-5.055.001-7.582-.002-.634-.042-1.267-.22-1.883-.327-1.134-1.139-1.712-2.328-1.649-1.622.086-2.466.889-2.673 2.539a9.404 9.404 0 0 0-.072 1.186c.002 2.458-.003 4.916.006 7.374.001.245-.06.317-.311.315-1.378-.012-2.756-.01-4.135-.001-.221.002-.284-.058-.284-.281.006-4.867.006-9.734 0-14.6-.001-.241.078-.297.306-.295 1.309.01 2.618.011 3.927-.001.24-.002.303.076.297.304-.013.581-.004 1.163-.004 1.745ZM13.44 22.686c0 2.408-.005 4.815.005 7.223.001.267-.065.348-.342.345a233.933 233.933 0 0 0-4.104-.002c-.219.002-.285-.053-.285-.28.007-4.874.006-9.749.002-14.624-.001-.201.048-.274.262-.272 1.388.009 2.776.012 4.163-.002.267-.002.303.101.303.329-.007 2.428-.004 4.856-.004 7.283ZM13.817 10.245a2.742 2.742 0 0 1-2.734 2.744c-1.488 0-2.726-1.232-2.731-2.72a2.746 2.746 0 0 1 2.737-2.742 2.74 2.74 0 0 1 2.728 2.718Z"
        />
    </svg>
)
export default IconLinkdin