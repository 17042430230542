import React, { useEffect, useState } from "react";

import FrontVideo from "../components/homevideo";
import HeaderTitle from "../components/headerTitle";
import { API_BASE_URL } from "../config/config";
import Information from "../components/CommonSections/Information";
import WhatWeOffer from "../components/CommonSections/WhatWeOffer";
import Advantage from "../components/CommonSections/Advantage";
import Partners from "../components/CommonSections/Partners";
import CtaBanner from "../components/CommonSections/CtaBanner";
import MetaTag from "../components/CommonSections/MetaTag";
import axios from "axios";
import PageLoader from "../components/home/pageload";
import ServiceSchema from "../components/CommonSections/serviceSchema";

export default function CyberSecurityServices() {

   const [cyberServicesData, setCyberServicesData] = useState(null);
   const [partnersData, setPartnersData] = useState(null);
   const [cybersecurityServicesData, setCybersecurityServicesData] = useState(null);


   useEffect(() => {
      axios.post(`${API_BASE_URL}/api/cyberservices/getCyberServices`).then((response) => {

         setCyberServicesData(response);

         const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;
         const partnersData = generalSettingsData.find((item) => item.tag === "cybersecurity_services_partners") || null;
         const cybersecurityServicesData = generalSettingsData.find((item) => item.tag === "cyberSecurity_advantage") || null;

         const partnersResponse = JSON.parse(partnersData?.response || "{}");
         const cybersecurityResponse = JSON.parse(cybersecurityServicesData?.response || "{}");
         cybersecurityResponse.advantages = cybersecurityResponse;
         setCybersecurityServicesData(cybersecurityResponse);
         setPartnersData(partnersResponse);

      }).catch((error) => {
         <PageLoader />
      })
   }, [])


   return (

      <>
         {cyberServicesData === null ? (
            <PageLoader />
         ) : (
            <>
            <ServiceSchema title={cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0].title} url='https://ishantechnologies.com/cybersecurity-services' />
            <MetaTag data={cyberServicesData} pageKey="cyber_services"/>
               <div className="page_heading responsive_heading">
                  <div className="container">
                     <HeaderTitle headerTitledata={cyberServicesData} pageKey="cyber_services" />
                  </div>
               </div>
               <div className="video hero-banner">
                  <FrontVideo homeData={cyberServicesData} pageKey="cyber_services" />

                  <div className="banner-content ">
                     {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0]?.banner_title && (
                     <h2 className='home-heading'>
                           {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0].banner_title}
                     </h2>
                     )}

                     {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0]?.banner_img_desc && (
                     <p className="para">
                           {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0].banner_img_desc}
                     </p>
                     )}

                     {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0]?.button_img_name && (
                     <a href={cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0].button_url}>
                           <button className="button hero-btn">
                           {cyberServicesData?.data?.data?.cyber_services?.cyber_services_banner_data
                  [0]?.button_img_name}
                           </button>
                     </a>
                     )}
                  </div>  
               </div>

               <Information homeData={cyberServicesData?.data?.data?.cyber_services?.cyber_services_information_data[0]?.description} />

               <WhatWeOffer title={cybersecurityServicesData?.what_we_offer} data={cyberServicesData?.data?.data?.cyber_services?.cyber_services_offer_data} />

               <Advantage title={cybersecurityServicesData?.advantages} data={cyberServicesData?.data?.data?.cyber_services?.cyber_services_advantages_data} />

               <Partners title={partnersData?.title} data={cyberServicesData?.data?.data?.cyber_services?.partners_data} />

               <CtaBanner bannerData={cyberServicesData?.data?.data?.cyber_services?.cyber_services_cta_banner_data} />
            </>
         )}
      </>

   );
}