import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_BASE_URL } from "../config/config";
import PageLoader from "../components/home/pageload";
import MetaTag from "../components/CommonSections/MetaTag";

export default function OurPartners() {

     useEffect(() => {
          getData();
     }, [])

     const [partnersData, setPartnersData] = useState(null);
     const [metaData, setMetadata] = useState(null);
     const [bannerTitle, setBannerTitle] = useState(null);

     const getData = () => {

          axios.post(`${API_BASE_URL}/api/ourPartners/getAllourPartners`).then((response) => {

               if (response.data.error === false) {
                    setPartnersData(response.data.data);
                    setMetadata(response);
                    const bannerTitleResponse = JSON.parse(response.data.data?.our_partners?.banner_data[0]?.response || "{}");
                    setBannerTitle(bannerTitleResponse);

               }
          }).catch((error) => {
               <PageLoader />
          })
     }
     return (
          <>
               {partnersData !== null &&
                    <>
                    <MetaTag data={metaData} pageKey="our_partners"/>
                         <div className="page_heading responsive_heading">
                              <div className="container">
                                   <h1>{bannerTitle?.title}</h1><p>{bannerTitle?.sub_title}<span>{bannerTitle?.italic}</span></p>
                              </div>
                         </div>
                         <div className="our-partners-details md-section-space">
                              <div className="container" dangerouslySetInnerHTML={{ __html: partnersData?.our_partners?.information_data[0]?.description }}>

                              </div>
                              <div className="our-partners">
                                   <div className="container">
                                        <h3>Our Partners</h3>
                                        <div className="partners-icons">
                                             <ul>
                                                  {partnersData?.our_partners?.our_partners_data.map((tag, index) => (
                                                       <li>{<img src={tag?.image} alt={tag?.altText} height={47} width={158} loading="lazy"/>}</li>
                                                  ))}
                                             </ul>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </>
               }
          </>
     );
}