import React, { useState, useEffect } from 'react';
import FrontVideo from "../../components/homevideo";
import HeaderTitle from "../../components/headerTitle";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import WhatWeOffer from "../../components/CommonSections/WhatWeOffer";
import Advantage from "../../components/CommonSections/Advantage";
import CtaBanner from "../../components/CommonSections/CtaBanner";
import PageLoader from '../../components/home/pageload';
import MetaTag from "../../components/CommonSections/MetaTag";
import ImgCloudPlatformManaged from "../../assest/images/IshanCloud Platform Managed.png";
import SubServiceSchema from "../../components/CommonSections/subServicesSchema";

export default function CloudPlatform() {
  useEffect(() => {
    getData();
  }, [])

  const [ishanCloudPlatformServicesData, setIshanCloudPlatformServicesData] = useState(null);
  const [settingResponse, setSettingResponse] = useState(null);

  const getData = () => {
    axios.post(`${API_BASE_URL}/api/ishancloudplatform/getIshanCloudPlatform`).then((response) => {

      setIshanCloudPlatformServicesData(response);

      const generalSettingsData = response?.data?.data?.general_settings?.general_settings_data;

      const ishanCloudPlatformServicesSettingsData = generalSettingsData.find((item) => item.tag === "ishan_cloud_platform_advantage") || null;
      const ishanCloudPlatformServicesSettingsResponse = JSON.parse(ishanCloudPlatformServicesSettingsData?.response || "{}");
      ishanCloudPlatformServicesSettingsResponse.advantages = ishanCloudPlatformServicesSettingsResponse;
      setSettingResponse(ishanCloudPlatformServicesSettingsResponse);
    }).catch((error) => {
      <PageLoader />
    })
  }
  return (

    <>

      {ishanCloudPlatformServicesData === null ? (
        <PageLoader />
      ) : (
        <>
         <SubServiceSchema title={ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0].title} url='https://ishantechnologies.com/data-center-services/cloud-platform' parentTitle='Data Centre and Cloud Services' parentUrl='https://ishantechnologies.com/data-center-services' />
          <MetaTag data={ishanCloudPlatformServicesData} pageKey="ishan_cloud_platform_services"/>
          <div className="page_heading responsive_heading">
            <div className="container">
              <HeaderTitle headerTitledata={ishanCloudPlatformServicesData} pageKey="ishan_cloud_platform_services" />
            </div>
          </div>
          <div className="video hero-banner">
            <FrontVideo homeData={ishanCloudPlatformServicesData} pageKey="ishan_cloud_platform_services" />

            <div className="banner-content ">
              {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0]?.banner_title && (
              <h2 className='home-heading'>
                    {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0].banner_title}
              </h2>
              )}

              {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0]?.banner_img_desc && (
              <p className="para">
                    {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0].banner_img_desc}
              </p>
              )}

              {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0]?.button_img_name && (
              <a href={ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0].button_url}>
                    <button className="button hero-btn">
                    {ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_banner_data
            [0]?.button_img_name}
                    </button>
              </a>
              )}
            </div>  
          </div>


          <div className="empowering section-space spin-wrap">
            <div className="spin-round-wrap">
              <div className="spin-round color-navy-blue">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>

            <div className="container">
              <div className="empowering-inner">
                <div className="empowering-left">
                  {<img src={ImgCloudPlatformManaged} alt="Ishan Cloud Platform" height={60} width={60} loading="lazy"/>}
                </div>
                <div className="empowering-right" dangerouslySetInnerHTML={{ __html: ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_information_data[0]?.description }}>
                </div>
              </div>
            </div>

            <div className="spin-round-wrap">
              <div className="spin-round">
                <span className='ballDot1'>1</span>
                <span className='ballDot2'>2</span>
                <span className='ballDot3'>3</span>
                <span className='ballDot4'>4</span>
              </div>
            </div>
          </div>

          <div className="weoffer-bgdark">
            <WhatWeOffer title={settingResponse?.what_we_offer} data={ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_offer_data} />
          </div>

          <Advantage title={settingResponse?.advantages} data={ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_advantages_data} />

          <CtaBanner bannerData={ishanCloudPlatformServicesData?.data?.data?.ishan_cloud_platform_services?.ishan_cloud_platform_services_cta_banner_data} />
        </>
      )}
    </>
  );
}