import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ClientExperiences({ homeData, pageKey, tag }) { 

  const [testimonials, setTestimonials] = useState([]);
  const [generalServicesData, setGenaralServicesData] = useState({});

  const getEmbedUrl = (url) => {
    if (typeof url !== 'string' || !url) {
      return ''; 
    }
    
    const videoIdMatch = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/);
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}?controls=0&cc_load_policy=0` : url;
  };
  
  
  
  
  useEffect(() => {

    if (homeData && homeData.data && homeData.data.data && homeData.data.data?.[pageKey] && homeData.data.data?.[pageKey]?.testimonial_info_data) {
      const testimonialData = homeData.data.data?.[pageKey]?.testimonial_info_data;
      setTestimonials(testimonialData);
    } else {

      if (homeData) {
        setTestimonials(homeData);
      } else {
        setTestimonials([])
      }

    }

    if (homeData && homeData.data && homeData.data.data && homeData.data.data.general_settings && homeData.data.data.general_settings.general_settings_data) {
      const general_setting_data = homeData.data.data.general_settings.general_settings_data;

      let servide_display = null;
      general_setting_data.map((service_data_info) => {
        if (service_data_info.tag == `${tag}_testimonial`) {
          servide_display = service_data_info;
        }
      })

      if (servide_display) {
        const parsedData = JSON.parse(servide_display.response);
        setGenaralServicesData(parsedData);
      } else {
        setGenaralServicesData(null);
      }

    }

  }, [homeData])

  var settings = {
    dots: false,
    arrow: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  
  return (
    <>
      <div className="container">

        {testimonials && testimonials.length > 0 ? (
          <>
            {generalServicesData ? (<h2>{generalServicesData?.title}</h2>) : null}
            <div className="client-wrap">
              <Slider {...settings}>
                {/* {testimonials.map((testimonial) => (
                  <div className="item" key={testimonial.id}>
                    <div className="img">
                      <figure>
                        <img
                          src={testimonial.image}
                          alt={testimonial.altText}
                          height={269}
                          width={269}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div className="content">
                      <div className="content-inner">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: testimonial.description,
                          }}
                        />
                        <span className="quote-ic"></span>
                      </div>
                      <div className="name">
                        <span>{testimonial.name}</span>
                        <span className="position">{testimonial.role}</span>
                      </div>
                    </div>
                  </div>
                ))} */}


                  {testimonials.map((testimonial) => (
                    <div className="testimonial-sec"> 

                      {pageKey === 'home' || tag === 'smart_city' && testimonial.video_url ? (
                      <div className="slider-vid-section mt-4">
                          <div className="section-row">
                              <div className="vid-col">
                                <iframe 
                                  width="560" 
                                  height="315" 
                                  src={getEmbedUrl(testimonial.video_url)} 
                                  title={testimonial.video_title} 
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                  allowFullScreen
                                ></iframe>

                              </div>
                              <div className="text-col">
                                <h3 className="head-desc">{testimonial.video_title}</h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: testimonial.description,
                                  }}
                                />

                                {testimonial.video_url && (
                                  <a className="button" href={testimonial.video_url} target="_blank" rel="noopener noreferrer">
                                    Know more
                                  </a>
                                )}
                              </div>
                          </div>
                      </div>
                
                      ) : (
                      <div className="item" key={testimonial.id}>
                        <div className="img">
                          <figure>
                            <img
                              src={testimonial.image}
                              alt={testimonial.altText}
                              height={269}
                              width={269}
                              loading="lazy"
                            />
                          </figure>
                        </div>
                        <div className="content">
                          <div className="content-inner">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: testimonial.description,
                              }}
                            />
                            <span className="quote-ic"></span>
                          </div>
                          <div className="name">
                            <span>{testimonial.name}</span>
                            <span className="position">{testimonial.role}</span>
                          </div>
                        </div>
                         </div>
                      )}
                    </div>
                  ))}
              </Slider>
            </div>
          </>

        ) : null}
      </div>
    </>
  );
}