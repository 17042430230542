import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CarouselComponent({ careerData = [] }) {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        pauseOnHover: true,
        speed: 1200,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings}>
            {careerData && careerData?.map((sliderImg) => (
                <div className="our-points-left" key={sliderImg.id}>
                    <div className="our-points-left-inner">
                        {<img src={sliderImg.image} alt={sliderImg.altText} height={307} width={410} loading="lazy"/>}
                    </div>
                </div>
            ))}
        </Slider>
    );
}

export default CarouselComponent;