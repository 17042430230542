import React from 'react'

const Information = ({ homeData = [], classData = [] }) => {

  const containerClass = classData ? `securing section-space ${classData}` : 'securing section-space';
  return (
    <div className={containerClass}>
      <div className="container" dangerouslySetInnerHTML={{ __html: homeData }}>
      </div>
    </div>
  )
}

export default Information