import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";


export default function Blogs({ homeData, pageKey }) {

  const [latestData, setLatestData] = useState(null);
  useEffect(() => {

    if (homeData && homeData.data && homeData.data.data && homeData.data.data?.[pageKey] && homeData.data.data?.[pageKey]?.blogs_info_data) {
      const blogsData = homeData.data.data?.[pageKey]?.blogs_info_data;
      
      setLatestData(blogsData);
    } else {
      if (homeData) {

        setLatestData(homeData);

      } else {
        setLatestData([])
      }
    }
  }, [homeData])
  var settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 7000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 757,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  function truncateDescription(description) {
    if (!description) {
      return '';
    }
    const words = description.split(' ');
    if (words.length > 10) {
      const truncatedText = words.slice(0, 9).join(' ');
      const truncatedWithEllipsis = truncatedText + '...';
      return truncatedWithEllipsis;
    } else {
      return description;
    }
  }
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  return (
    <>
      {latestData && latestData.length > 0 &&
        <div className="container">
          <h2>Latest Updates</h2>
          <div className="blog_wrap">
            <Slider {...settings}>
              {latestData.map((blog) => (
                <div className="item">
                  <figure>
                    <img src={blog?.image} alt={blog?.altText} height={190} width={338} loading="lazy"/>
                  </figure>
                  <h3>{truncateDescription(blog?.title)}</h3>
                  {/* <div dangerouslySetInnerHTML={{ __html: truncateDescription(blog?.description) }}></div> */}
                  <Link to={`/latest-updates${blog?.button_url}`} className="button" onClick={handleClick}>
                    Know more
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      }
    </>
  );
}