import React, { useState, useEffect } from 'react';
import FormsLinks from "../contacts/forms-links";
import axios from "axios";
import { API_BASE_URL, CRM_URL, SITE_KEY,FORM_AUTH_TOKEN } from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import { xssSafeString, sanitizeInput, getApiAccessToken } from '../../utils/helper';

import {
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Helmet } from 'react-helmet';


export default function ContactForSales() {
  const [getAuthToken, setAuthToken] = useState(null);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        var result = await getApiAccessToken();
        setAuthToken(result.token);
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };
    fetchToken();
    const tokenInterval = setInterval(fetchToken, 60000);
    return () => clearInterval(tokenInterval);
  }, [])


  useEffect(() => {
    ReactRecaptcha3.init(SITE_KEY).then(
      (status) => {

      }
    );
  }, [])

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pageName, setPageName] = useState("");
  const [navigation, setNav] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const currentUrl = window.location.href;
  const cleanedUrl = currentUrl.replace(/\/$/, ''); 



  const handleSubmit = (newNav) => {
    setErrorMsg(false);
    ReactRecaptcha3.getToken().then(
      (token) => {
        let data = {
          Details: newNav,
          token: token
        }

        axios.post(`${CRM_URL}?cmd=contact_for_sale`, data,{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken}`
          }
        }).then((response) => {

        handleClick();
          setFormSubmitted(true);
          axios.post(`${API_BASE_URL}/api/website/createContactForSale`, data)
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        }).catch((error) => {
          setErrorMsg(true);
        });
      })
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  }

const validation = useFormik({
  enableReinitialize: true,

  initialValues: {
    id: (navigation && navigation.id) || "",
    business_email: (navigation && navigation.business_email) || "",
    name: (navigation && navigation.name) || "",
    comp_name: (navigation && navigation.comp_name) || "",
    phone_no: (navigation && navigation.phone_no) || "",
    designation: (navigation && navigation.designation) || "",
    service_requirement: (navigation && navigation.service_requirement) || "",
    existing_cust: (navigation && navigation.existing_cust) || "",
    requirement_desc: (navigation && navigation.requirement_desc) || "",
  },

  validationSchema: Yup.object({
    business_email: Yup.string()
      .required('Please Enter Business Email')
      .email('Invalid Email Address')
      .transform((value) => sanitizeInput(value)), // Sanitize email input

    name: xssSafeString("Please Enter Name").max(50, 'Must be 50 characters or less'),
    comp_name: xssSafeString("Please Enter Company Name").max(50, 'Must be 50 characters or less'),
    
    phone_no: Yup.string()
      .required('Please Enter Phone Number')
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .transform((value) => sanitizeInput(value)), // Sanitize phone number

    designation: xssSafeString("Please Enter Designation").max(50, 'Must be 50 characters or less'),
    service_requirement: xssSafeString("Please select Service").max(50, 'Must be 50 characters or less'),
    existing_cust: xssSafeString("Please select customer").max(50, 'Must be 50 characters or less'),
    requirement_desc: xssSafeString("Please Enter Description"),
  }),

  onSubmit: (values) => {
    const sanitizedValues = {
      business_email: sanitizeInput(values.business_email),
      name: sanitizeInput(values.name),
      comp_name: sanitizeInput(values.comp_name),
      phone_no: sanitizeInput(values.phone_no),
      designation: sanitizeInput(values.designation),
      service_requirement: sanitizeInput(values.service_requirement),
      existing_cust: sanitizeInput(values.existing_cust),
      requirement_desc: sanitizeInput(values.requirement_desc),
    };

    handleSubmit(sanitizedValues);

    validation.resetForm();
  },
});


  useEffect(() => {

    const currentPathname = window.location.pathname;
    const pathWithoutTrailingSlash = currentPathname.endsWith("/")
      ? currentPathname.slice(0, -1)
      : currentPathname;
    const parts = pathWithoutTrailingSlash.split("/");
    const lastPart = parts[parts.length - 1];
    setPageName(lastPart);
  }, []);
  return (
    <>
     <Helmet>
            <link rel="canonical" href={cleanedUrl} />
            <title>Contact Ishan Technologies for Sales: Contact Our Sales Team</title>
            <meta name="description" content="Contact Ishan Technologies for Sales: Interested in our solutions? Connect with our sales team for inquiries. Contact us now to get started!" />
        </Helmet>
      <div className="page_heading">
        <div className="container">
          <h1>Contact For Sales</h1>  <p>UNIQUELY<span>present</span></p>
        </div>
      </div>
      <FormsLinks slug={pageName} />
      <div className="cms contact-fields section-space">
        <div className="container">
          <div className="form-wrapper">

            <Form
              id="createNav"
              className={formSubmitted && ("submitted-success")}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <h2>Contact For Sales</h2>
              {formSubmitted ? (
                <>
                  <div className='thanks-msg'>
                    <div className="main-container">
                      <div className="check-container">
                        <div className="check-background">
                          <svg viewBox="0 0 65 51" fill="none" xmlns="https://www.w3.org/2000/svg">
                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <div className="check-shadow"></div>
                      </div>
                      <h4>"Thank you - We'll be in touch shortly to assist you."</h4>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='item'>
                    <label htmlFor="email">Business Email ID:</label>
                    <Input type="email" id="business_email" name="business_email" onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.business_email || ""}
                      invalid={
                        validation.touched.business_email && validation.errors.business_email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.business_email && validation.errors.business_email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.business_email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="name">Name:</label>
                    <Input type="text" id="name" name="name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="comp_name">Company Name:</label>
                    <Input type="text" id="comp_name" name="comp_name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.comp_name || ""}
                      invalid={
                        validation.touched.comp_name && validation.errors.comp_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.comp_name && validation.errors.comp_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.comp_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="phone_no">Phone Number:</label>
                    <Input type="text" id="phone_no" name="phone_no"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_no || ""}
                      invalid={
                        validation.touched.phone_no && validation.errors.phone_no
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone_no && validation.errors.phone_no ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone_no}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="designation">Designation:</label>
                    <Input type="text" id="designation" name="designation"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.designation || ""}
                      invalid={
                        validation.touched.designation && validation.errors.designation
                          ? true
                          : false
                      }
                    />
                    {validation.touched.designation && validation.errors.designation ? (
                      <FormFeedback type="invalid">
                        {validation.errors.designation}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label>Please select a service:</label>
                    <div className="rgt">
                      <span>
                        <Input type="radio" name="service_requirement" value="Retail Broadband" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'Retail Broadband'} />
                        <span className="label-radio">Retail Broadband</span>
                      </span>
                      <span>
                        <Input type="radio" name="service_requirement" value="Enterprise Connectivity" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'Enterprise Connectivity'} />
                        <span className="label-radio">Enterprise Connectivity</span>
                      </span>
                      <span>
                        <Input type="radio" name="service_requirement" value="Cloud Services" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'Cloud Services'} />
                        <span className="label-radio">Cloud Services </span>
                      </span>
                      <span>
                        <Input type="radio" name="service_requirement" value="Colocation" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'Colocation'} />
                        <span className="label-radio">Colocation</span>
                      </span>
                      <span>
                        <Input type="radio" name="service_requirement" value="System Integration" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'System Integration'} />
                        <span className="label-radio">System Integration</span>
                      </span>
                      <span>
                        <Input type="radio" name="service_requirement" value="Cyber Security Services" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.service_requirement === 'Cyber Security Services'} />
                        <span className="label-radio">Cyber Security Services </span>
                      </span>
                    </div>
                    {validation.touched.service_requirement && validation.errors.service_requirement ? (
                      <FormFeedback type="invalid">
                        {validation.errors.service_requirement}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label>Existing customer</label>
                    <div className="rgt">
                      <span>
                        <Input type="radio" name="existing_cust" value="Yes" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.existing_cust === 'Yes'} />
                        <span className="label-radio">Yes</span>
                      </span>
                      <span>
                        <Input type="radio" name="existing_cust" value="No" onChange={validation.handleChange}
                          onBlur={validation.handleBlur} checked={validation.values.existing_cust === 'No'} />
                        <span className="label-radio">No</span>
                      </span>
                    </div>
                    {validation.touched.existing_cust && validation.errors.existing_cust ? (
                      <FormFeedback type="invalid">
                        {validation.errors.existing_cust}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className='item'>
                    <label htmlFor="requirement_desc">Requirement Description:</label>
                    <textarea
                      name="requirement_desc"
                      value={validation.values.requirement_desc}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={`form-control ${validation.touched.requirement_desc && validation.errors.requirement_desc ? 'is-invalid' : ''
                        }`}
                    ></textarea>
                    {validation.touched.requirement_desc && validation.errors.requirement_desc ? (
                      <div className="invalid-feedback">{validation.errors.requirement_desc}</div>
                    ) : null}
                  </div>
                  <div className='item submit-item'>
                    <button type="submit" className='button btn-midnight-blue'>Submit</button>
                  </div>
                  {errorMsg &&
                    <div className="form-error-msg onlyform-text-center">
                      <span className='errorMsg'>Something Went Wrong!, Please try again later.</span>
                    </div>
                  }
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}					