import * as React from "react"
const IconTwitter = (props) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width={38}
    height={38}
    viewBox="0 0 152 152"
    {...props}
  >
    <svg id="fi_5969020" enable-background="new 0 0 1227 1227" viewBox="0 0 1227 1227" xmlns="https://www.w3.org/2000/svg">
      <g>
        <path d="m613.5 0c-338.815 0-613.5 274.685-613.5 613.5s274.685 613.5 613.5 613.5 613.5-274.685 613.5-613.5-274.685-613.5-613.5-613.5z"></path>
        <path d="m680.617 557.98 262.632-305.288h-62.235l-228.044 265.078-182.137-265.078h-210.074l275.427 400.844-275.427 320.142h62.239l240.82-279.931 192.35 279.931h210.074l-285.641-415.698zm-335.194-258.435h95.595l440.024 629.411h-95.595z" fill="#fff"></path>
      </g>
    </svg>
  </svg>
)
export default IconTwitter